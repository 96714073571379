import { Injectable } from '@angular/core';
import { UserInfo } from '@angular/fire/auth';
import { Functions, getFunctions, httpsCallable, HttpsCallableResult } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {
  functions: Functions = getFunctions();

  constructor() { }

  private listUsersFunction = httpsCallable(this.functions, 'listUsers');
  private subscribeToTopicFunction = httpsCallable(this.functions, 'subscribeToTopic');
  private pushNotificationsSubscribeFunction = httpsCallable(this.functions, 'pushNotificationsSubscribe');

  listUsers = async (): Promise<UserInfo[]> => {
    const result: HttpsCallableResult<any> = await this.listUsersFunction();
    return result.data.users as UserInfo[];
  }

  pushNotificationsSubscribe = async (): Promise<string>  => {
    const result: HttpsCallableResult<any> = await this.pushNotificationsSubscribeFunction();
    return result.data.message as string;
  }
}
