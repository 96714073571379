import { Component } from '@angular/core';

@Component({
  standalone: false,
  selector: 'kc-admin-business-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class BusinessViewComponent {

}
