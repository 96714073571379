<h1 class="page-title">Know Your Customer</h1>

<div *ngIf="showLoading">
    <div>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>        
</div>

<mat-accordion class="expansion-headers-align">
    <mat-expansion-panel [expanded]="step() === 0" (opened)="setStep(0)" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title> Query Data </mat-panel-title>
        <mat-panel-description>
          Fill in the form
          <mat-icon>search</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
  
        <form [formGroup]="kycQueryForm" (ngSubmit)="submitQuery(kycQueryForm.value)">
            <mat-form-field>
                <mat-label>KYC Passed</mat-label>
                <mat-select formControlName="passed">
                    <mat-option [value]="true">True</mat-option>
                    <mat-option [value]="false">False</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>KYC Email</mat-label>
                <input formControlName="email" matInput>
            </mat-form-field>
            <mat-form-field>
                <mat-label>KYC Phone</mat-label>
                <input formControlName="phone" matInput>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Profile ID</mat-label>
                <input formControlName="profile_id" matInput>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Profile Email</mat-label>
                <input formControlName="$profile.user.email$" matInput>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Profile Phone</mat-label>
                <input formControlName="$profile.user.phone$" matInput>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Profile Role</mat-label>
                <mat-select formControlName="$profile.profile_roles.role$">
                    <mat-option *ngFor="let role of RolesEnumValues" [value]="role">{{ role }}</mat-option>
                </mat-select>
            </mat-form-field>

            <br>

            <button mat-raised-button color="accent" [disabled]="showLoading">Query</button>
        </form>
    </mat-expansion-panel>
  
    <mat-expansion-panel [expanded]="step() === 1" (opened)="setStep(1)" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title> Search Results </mat-panel-title>
            <mat-panel-description>
                A Tabular Representation
                <mat-icon>database_search</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
  
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="profile_photo">
                <th mat-header-cell *matHeaderCellDef>  </th>
                <td mat-cell *matCellDef="let kyc">
                    <img height="40" width="40" class="avatar-thumbnail" alt="avatar" src="{{ kyc.profile?.avatarversion ? ( staticBaseUrl + '/profiles/' + kyc.profile?.id + '/avatars/' + kyc.profile?.avatarversion + '.jpeg' ) : ( staticBaseUrl + '/defaults/avatar.svg') }}"/>
                </td>
            </ng-container>

            <ng-container matColumnDef="passed">
                <th mat-header-cell *matHeaderCellDef> KYC Passed </th>
                <td mat-cell *matCellDef="let kyc"> {{ kyc.passed }} </td>
            </ng-container>

            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef> KYC Phone </th>
                <td mat-cell *matCellDef="let kyc"> {{ kyc.phone }} </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef> KYC Email </th>
                <td mat-cell *matCellDef="let kyc"> {{ kyc.email }} </td>
            </ng-container>

            <ng-container matColumnDef="profile_name">
                <th mat-header-cell *matHeaderCellDef> Profile Name </th>
                <td mat-cell *matCellDef="let kyc"> {{ kyc.profile.firstname }} {{ kyc.profile.lastname }} </td>
            </ng-container>

            <ng-container matColumnDef="profile_user_phone">
                <th mat-header-cell *matHeaderCellDef> Profile User Phone </th>
                <td mat-cell *matCellDef="let kyc"> {{ kyc.profile.user.phone }} </td>
            </ng-container>

            <ng-container matColumnDef="profile_user_email">
                <th mat-header-cell *matHeaderCellDef> Profile User Email </th>
                <td mat-cell *matCellDef="let kyc"> {{ kyc.profile.user.email }} </td>
            </ng-container>

            <ng-container matColumnDef="profile_country">
                <th mat-header-cell *matHeaderCellDef> Country </th>
                <td mat-cell *matCellDef="let kyc"> {{ kyc.profile.country?.name }} </td>
            </ng-container>

            <ng-container matColumnDef="profile_dob">
                <th mat-header-cell *matHeaderCellDef> Date of Birth </th>
                <td mat-cell *matCellDef="let kyc"> {{ kyc.profile.dob | date : "mediumDate" }} </td>
            </ng-container>

            <ng-container matColumnDef="profile_genderidentity">
                <th mat-header-cell *matHeaderCellDef> Gender </th>
                <td mat-cell *matCellDef="let kyc"> {{ kyc.profile.genderidentity }} </td>
            </ng-container>

            <ng-container matColumnDef="profile_roles">
                <th mat-header-cell *matHeaderCellDef> Roles </th>
                <td mat-cell *matCellDef="let kyc"> {{ getProfileRoles(kyc.profile.profile_roles) }} </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let kyc">
                    <button mat-button color="accent" (click)="updateKyc(kyc.id, true)" [disabled]="showLoading" *ngIf="kyc.passed === false">Pass User</button>
                    <button mat-button color="primary" (click)="updateKyc(kyc.id, false)" [disabled]="showLoading" *ngIf="kyc.passed === true">Fail User</button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let kyc; columns: displayedColumns;"></tr>
        </table>
        
        <mat-paginator [pageSizeOptions]="[5, 10, 20]"
            showFirstLastButtons
            aria-label="Select page of periodic elements">
        </mat-paginator>

        <mat-action-row>
            <button mat-button (click)="prevStep()">Previous</button>
            <button mat-button (click)="nextStep()">Next</button>
        </mat-action-row>
    </mat-expansion-panel>
  
    <mat-expansion-panel [expanded]="step() === 2" (opened)="setStep(2)" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title> Advanced Search Results </mat-panel-title>
            <mat-panel-description>
                The Raw Data as JSON
                <mat-icon>search_insights</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <ng-container *ngIf="kycs.length == 0">
            <p>No Results Found</p>
            <button mat-stroked-button (click)="setStep(0)">Back to Search</button>
        </ng-container>

        <ng-container *ngIf="kycs.length > 0">
            <mat-nav-list>
                <mat-list-item>
                    <img matListItemAvatar alt="avatar" src="{{ kycs[selectedIndex].profile.avatarversion ? ( staticBaseUrl + '/profiles/' + kycs[selectedIndex].profile.id + '/avatars/' + kycs[selectedIndex].profile.avatarversion + '.jpeg' ) : ( staticBaseUrl + '/defaults/avatar.svg') }}"/>
                    <span matListItemLine>KYC ID : {{ kycs[selectedIndex].id }}</span> 
                    <span matListItemLine>KYC Phone : {{ kycs[selectedIndex].phone }}</span> 
                    <span matListItemLine>KYC Email : {{ kycs[selectedIndex].email }}</span>
                </mat-list-item>
            </mat-nav-list>
        </ng-container>
        
        <ng-container *ngIf="kycs.length">
            <data-viewer [data]="kycs" (selectedIndex)="onSelectedIndex($event)"></data-viewer>
        </ng-container>

        <mat-action-row>
            <button mat-button (click)="prevStep()">Previous</button>
            <button mat-button (click)="nextStep()">End</button>
        </mat-action-row>
    </mat-expansion-panel>
</mat-accordion>
