<h1 class="page-title">Know Your School</h1>

<div *ngIf="showLoading">
    <div>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>        
</div>

<mat-accordion class="expansion-headers-align">
    <mat-expansion-panel [expanded]="step() === 0" (opened)="setStep(0)" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title> Query Data </mat-panel-title>
        <mat-panel-description>
          Fill in the form
          <mat-icon>search</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
  
        <form [formGroup]="kysQueryForm" (ngSubmit)="submitQuery(kysQueryForm.value)">
            <mat-form-field>
                <mat-label>KYS Passed</mat-label>
                <mat-select formControlName="passed">
                    <mat-option [value]="true">True</mat-option>
                    <mat-option [value]="false">False</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>KYS Phone</mat-label>
                <input formControlName="phone" matInput>
            </mat-form-field>
            <mat-form-field>
                <mat-label>KYS Email</mat-label>
                <input formControlName="email" matInput>
            </mat-form-field>
            <mat-form-field>
                <mat-label>School ID</mat-label>
                <input formControlName="school_id" matInput>
            </mat-form-field>
            <mat-form-field>
                <mat-label>School Manager Phone</mat-label>
                <input formControlName="$school.managers.profile.user.phone$" matInput>
            </mat-form-field>
            <mat-form-field>
                <mat-label>School Manager Email</mat-label>
                <input formControlName="$school.managers.profile.user.email$" matInput>
            </mat-form-field>

            <br>

            <button mat-raised-button color="accent" [disabled]="showLoading">Query</button>
        </form>
    </mat-expansion-panel>
  
    <mat-expansion-panel [expanded]="step() === 1" (opened)="setStep(1)" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title> Search Results </mat-panel-title>
            <mat-panel-description>
                A Tabular Representation
                <mat-icon>database_search</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
  
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="school_logo">
                <th mat-header-cell *matHeaderCellDef>  </th>
                <td mat-cell *matCellDef="let kys">
                    <img height="40" width="40" class="logo-thumbnail" alt="logo" src="{{ kys.school?.logoversion ? ( staticBaseUrl + '/schools/' + kys.school?.id + '/logos/' + kys.school?.logoversion + '.jpeg' ) : ( staticBaseUrl + '/defaults/school.svg') }}"/>
                </td>
            </ng-container>

            <ng-container matColumnDef="passed">
                <th mat-header-cell *matHeaderCellDef> KYS Passed </th>
                <td mat-cell *matCellDef="let kys"> {{ kys.passed }} </td>
            </ng-container>

            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef> KYS Phone </th>
                <td mat-cell *matCellDef="let kys"> {{ kys.phone }} </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef> KYS Email </th>
                <td mat-cell *matCellDef="let kys"> {{ kys.email }} </td>
            </ng-container>

            <ng-container matColumnDef="school_name">
                <th mat-header-cell *matHeaderCellDef> School Name </th>
                <td mat-cell *matCellDef="let kys"> {{ kys.school.name }} </td>
            </ng-container>

            <ng-container matColumnDef="school_country">
                <th mat-header-cell *matHeaderCellDef> School Country </th>
                <td mat-cell *matCellDef="let kys"> {{ kys.school.country.name }} </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let kys">
                    <button mat-button color="accent" (click)="updateKys(kys.id, true)" [disabled]="showLoading" *ngIf="kys.passed === false">Pass User</button>
                    <button mat-button color="primary" (click)="updateKys(kys.id, false)" [disabled]="showLoading" *ngIf="kys.passed === true">Fail User</button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let kys; columns: displayedColumns;"></tr>
        </table>
        
        <mat-paginator [pageSizeOptions]="[5, 10, 20]"
            showFirstLastButtons
            aria-label="Select page of periodic elements">
        </mat-paginator>

        <mat-action-row>
            <button mat-button (click)="prevStep()">Previous</button>
            <button mat-button (click)="nextStep()">Next</button>
        </mat-action-row>
    </mat-expansion-panel>
  
    <mat-expansion-panel [expanded]="step() === 2" (opened)="setStep(2)" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title> Advanced Search Results </mat-panel-title>
            <mat-panel-description>
                The Raw Data as JSON
                <mat-icon>search_insights</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <ng-container *ngIf="kyss.length == 0">
            <p>No Results Found</p>
            <button mat-stroked-button (click)="setStep(0)">Back to Search</button>
        </ng-container>

        <ng-container *ngIf="kyss.length > 0">
            <mat-nav-list>
                <mat-list-item>
                    <img matListItemAvatar alt="avatar" src="{{ kyss[selectedIndex].school.logoversion ? ( staticBaseUrl + '/schools/' + kyss[selectedIndex].school.id + '/logos/' + kyss[selectedIndex].school.logoversion + '.jpeg' ) : ( staticBaseUrl + '/defaults/school.svg') }}"/>
                    <span matListItemLine>KYS ID : {{ kyss[selectedIndex].id }}</span> 
                    <span matListItemLine>KYS Phone : {{ kyss[selectedIndex].phone }}</span> 
                    <span matListItemLine>KYS Email : {{ kyss[selectedIndex].email }}</span>
                </mat-list-item>
            </mat-nav-list>
        </ng-container>
        
        <ng-container *ngIf="kyss.length">
            <data-viewer [data]="kyss" (selectedIndex)="onSelectedIndex($event)"></data-viewer>
        </ng-container>

        <mat-action-row>
            <button mat-button (click)="prevStep()">Previous</button>
            <button mat-button (click)="nextStep()">End</button>
        </mat-action-row>
    </mat-expansion-panel>
</mat-accordion>
