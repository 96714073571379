import { Component, signal, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SchoolInterface, SchoolStatusEnum } from '@kiddy-cash/common';
import { SchoolService, SnackBarService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
  standalone: false,
  selector: 'app-school',
  templateUrl: './school.component.html',
  styleUrl: './school.component.scss'
})
export class SchoolComponent {
  schools: SchoolInterface[] = [];
  SchoolStatusEnumValues = Object.values(SchoolStatusEnum);
  staticBaseUrl: string =  environment.staticBaseUrl;
  showLoading: boolean = false;

  schoolQueryForm: FormGroup = this.formBuilder.group({
    name: [''],
    country: '',
    status: '',
  })

  displayedColumns: string[] = [
    'logo', 
    'name', 
    'location',
    'country', 
    'balance', 
    'status', 
    'active', 
    'created', 
  ];

  dataSource = new MatTableDataSource<SchoolInterface>([]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  selectedIndex: number = 0;

  step = signal(0);

  setStep(index: number) {
    this.step.set(index);
  }

  nextStep() {
    this.step.update(i => i + 1);
  }

  prevStep() {
    this.step.update(i => i - 1);
  }

  constructor(
    private schoolService: SchoolService,
    private snackBarService: SnackBarService,
    private formBuilder: FormBuilder,
  ) {

   }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  submitQuery(formData: any){
    const data = structuredClone(formData)
    Object.keys(data).forEach((key) => (data[key] === null || data[key] === '') && delete data[key]);
    this.queryForSchools(data)
  }

  queryForSchools(data: any) {
    this.showLoading = true;
    this.schools = []
    this.selectedIndex = 0;

    this.schoolService.findSchools(data).subscribe(res => {
      const schools: SchoolInterface[] = res.data.schools;
      this.schools = schools;
      this.dataSource.data = schools;
      schools.length ? this.setStep(1) : this.setStep(2);
      this.showLoading = false;
    },(err: any) => {
      this.showLoading = false;
      this.snackBarService.presentToast(err.error.message, 'danger');
    })
  }

  onSelectedIndex(index: number) {
    this.selectedIndex = index;
  }

}
