import { Component } from '@angular/core';
import { User } from '@angular/fire/auth';
import { AuthService, FirebaseService, FirestoreService, PushNotificationsService } from './services';
import { RolesEnum } from '@kiddy-cash/common';

@Component({
  standalone: false,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  user: User | null = null;
  userRoles: RolesEnum[] = [];

  RolesEnum: typeof RolesEnum = RolesEnum

  constructor(
    private authService: AuthService, 
    private firebaseService: FirebaseService, 
    private firestoreService: FirestoreService, 
    private pushNotificationsService: PushNotificationsService
  ) {
  }

  async ngOnInit() {
    this.authService._$user.subscribe((user: User | null) => {
      this.user = user;
    });
    this.authService._$user_roles.subscribe((userRoles: RolesEnum[]) => {
      this.userRoles = userRoles;
    });
    this.firebaseService.setUp();
    this.firestoreService.setUp();
    this.pushNotificationsService.setUp();
  }

}
