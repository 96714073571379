import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CountryInterface } from '@kiddy-cash/common';
import { CountryService, SnackBarService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
  standalone: false,
  selector: 'kc-admin-country-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class CountryViewComponent {
  public country_id!: number;
  country!: CountryInterface;
  showLoading: boolean = true;
  staticBaseUrl: string =  environment.staticBaseUrl;

  constructor(
    private activatedRoute: ActivatedRoute,
    private countryService: CountryService,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit() {
    this.country_id = Number(this.activatedRoute.snapshot.paramMap.get('country_id') as string);

    this.countryService.getCountry(this.country_id).subscribe(res => {
      const country: CountryInterface = res.data.country;
      this.country = country;
      this.showLoading = false;
    },
    (err: any) => {
      this.snackBarService.presentToast(err.error.message, 'danger');
      this.showLoading = false;
    })
  }
}
