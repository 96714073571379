import { Component, ViewChild } from '@angular/core';
import { AdminUserService, FunctionsService, SnackBarService } from 'src/app/services';
import { UserInfo } from '@angular/fire/auth';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  standalone: false,
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent {
  displayedColumns: string[] = ['photo', 'name', 'email', 'roles', 'created', 'lastSignedIn'];
  showLoading: boolean = true;

  dataSource = new MatTableDataSource<UserInfo>([]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private adminUserService: AdminUserService,
    private functionsService: FunctionsService,
    private snackBarService: SnackBarService,
  ) { }

  async ngOnInit() {
    try {
      const admins: UserInfo[] = await this.functionsService.listUsers();
      this.showLoading = false;
      this.dataSource.data = admins;
    } catch (err: any) {
      this.snackBarService.presentToast(err.error.message, 'danger');
      this.showLoading = false;
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

}
