import { Component, signal, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { KnowYourBusinessInterface, BusinessTypeEnum, ProfileRoleInterface } from '@kiddy-cash/common';
import { KnowYourBusinessService, SnackBarService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
  standalone: false,
  selector: 'app-know-your-business',
  templateUrl: './know-your-business.component.html',
  styleUrl: './know-your-business.component.scss'
})
export class KnowYourBusinessComponent {
  kybs: KnowYourBusinessInterface[] = [];
  BusinessTypeEnumalues = Object.values(BusinessTypeEnum);
  staticBaseUrl: string =  environment.staticBaseUrl;
  showLoading: boolean = false;

  kybQueryForm: FormGroup = this.formBuilder.group({
    passed: '',
    phone: '',
    email: ['', Validators.email],
    business_id: '',
    '$business.type$': '',
    '$business.managers.profile.user.phone$': '',
    '$business.managers.profile.user.email$': ['', Validators.email],
  })

  displayedColumns: string[] = [
    'business_logo', 
    'passed',
    'phone',
    'email', 
    'business_name', 
    'business_country',
    'business_company_type', 
    'business_total_followers', 
    'action', 
  ];

  dataSource = new MatTableDataSource<KnowYourBusinessInterface>([]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  selectedIndex: number = 0;

  step = signal(0);

  setStep(index: number) {
    this.step.set(index);
  }

  nextStep() {
    this.step.update(i => i + 1);
  }

  prevStep() {
    this.step.update(i => i - 1);
  }

  queryData: object = {}
 
  constructor(
    private kybService: KnowYourBusinessService,
    private snackBarService: SnackBarService,
    private formBuilder: FormBuilder,
  ) {

   }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  submitQuery(formData: any){
    const data = structuredClone(formData)
    Object.keys(data).forEach((key) => (data[key] === null || data[key] === '') && delete data[key]);
    this.queryForKybs(data)
  }

  queryForKybs(data: any) {
    this.showLoading = true;
    this.kybs = []
    this.selectedIndex = 0;

    this.queryData = data;

    this.kybService.queryKYBs(data).subscribe(res => {
      const kybs: KnowYourBusinessInterface[] = res.data.kybs;
      this.kybs = kybs;
      this.dataSource.data = kybs;
      kybs.length ? this.setStep(1) : this.setStep(2);
      this.showLoading = false;
    },(err: any) => {
      this.showLoading = false;
      this.snackBarService.presentToast(err.error.message, 'danger');
    })
  }

  onSelectedIndex(index: number) {
    this.selectedIndex = index;
  }

  getProfileRoles(business_roles: ProfileRoleInterface[]): string {
    return business_roles.flatMap(business_role => business_role.role.title).toString();
  }

  updateKyb(kyb_id: number, passed: boolean) {
    this.showLoading = true;
    this.kybService.updateKYB(kyb_id, { passed: passed }).subscribe(res => {
      this.snackBarService.presentToast(res.message, 'success');
      this.queryForKybs(this.queryData);
    },(err: any) => {
      this.showLoading = false;
      this.snackBarService.presentToast(err.error.message, 'danger');
    })
  }

}
