<h1 class="page-title">Integrations</h1>

<div *ngIf="showLoading">
    <div>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>        
</div>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let integration"> {{ integration.id }} </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let integration"> {{ integration.name }} </td>
    </ng-container>

    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> Description </th>
        <td mat-cell *matCellDef="let integration"> {{ integration.description }} </td>
    </ng-container>

    <ng-container matColumnDef="service">
        <th mat-header-cell *matHeaderCellDef> Service </th>
        <td mat-cell *matCellDef="let integration"> {{ integration.service }} </td>
    </ng-container>

    <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef> Active </th>
        <td mat-cell *matCellDef="let integration"> {{ integration.active }} </td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef> Created </th>
        <td mat-cell *matCellDef="let integration"> {{ integration.createdAt | date : "mediumDate" }} </td>
    </ng-container>

    <ng-container matColumnDef="updatedAt">
        <th mat-header-cell *matHeaderCellDef> Updated </th>
        <td mat-cell *matCellDef="let integration"> {{ integration.updatedAt  | date : "mediumDate" }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let integration; columns: displayedColumns;" [routerLink]="['/integration', integration.id]"></tr>
</table>

<mat-paginator [pageSizeOptions]="[250, 100, 50, 20]"
    showFirstLastButtons
    aria-label="Select page of periodic elements">
</mat-paginator>