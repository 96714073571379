import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminUserService {

  constructor(private http: HttpClient) { }

  findAdmins() {
    return this.http.get<any>(`${environment.apiBaseUrl}/super-admin/user`);
  }

  getAdmin(uid: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/super-admin/user/${uid}`);
  }

  setAdminUserRoles(uid: string, data: any) {
    return this.http.put<any>(`${environment.apiBaseUrl}/super-admin/user/${uid}/roles`, data);
  }

  deleteAdmin(uid: string) {
    return this.http.delete<any>(`${environment.apiBaseUrl}/super-admin/user/${uid}`);
  }

}
