import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InternalPaymentsService {

  constructor(private http: HttpClient) { }

  findInternalPayments(data: any) {
    return this.http.get<any>(`${environment.apiBaseUrl}/internal-payments`, { params: data });
  }

  createInternalPaymentsIntegration(data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/internal-payments`, data);
  }

  getInternalPayments(internal_payments_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/internal-payments/${internal_payments_id}`);
  }

  transactInternalPaymentsWithIntegration(internal_payments_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/internal-payments/${internal_payments_id}/transact`, data);
  }

  updateInternalPayments(internal_payments_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/internal-payments/${internal_payments_id}`, data);
  }

  deleteInternalPayments(internal_payments_id: string) {
    return this.http.delete<any>(`${environment.apiBaseUrl}/internal-payments/${internal_payments_id}`);
  }

}
