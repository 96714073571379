import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class KnowYourBusinessService {

  constructor(private http: HttpClient) { }

  queryKYBs(data: any) {
    return this.http.get<any>(`${environment.apiBaseUrl}/know-your-business`, { params: data });
  }

  updateKYB(kyc_id: number, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/know-your-business/${kyc_id}`, data);
  }

}
