import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountTypeEnum, TransactionInterface, TransactionStatusEnum } from '@kiddy-cash/common';
import { TransactionService, SnackBarService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
  standalone: false,
  selector: 'kc-admin-transaction-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class TransactionViewComponent {
  public transaction_id!: string;
  transaction!: TransactionInterface;
  showLoading: boolean = true;
  staticBaseUrl: string =  environment.staticBaseUrl;
  TransactionStatusEnum = TransactionStatusEnum;
  AccountTypeEnum = AccountTypeEnum;

  constructor(
    private activatedRoute: ActivatedRoute,
    private transactionService: TransactionService,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit() {
    this.transaction_id = this.activatedRoute.snapshot.paramMap.get('transaction_id') as string;

    this.transactionService.getTransaction(this.transaction_id).subscribe(res => {
      const transaction: TransactionInterface = res.data.transaction;
      this.transaction = transaction;
      this.showLoading = false;
    },
    (err: any) => {
      this.snackBarService.presentToast(err.error.message, 'danger');
      this.showLoading = false;
    })
  }

  finalizeInternalPaymentTransaction(){
    this.showLoading = true;

    this.transactionService.finalizeInternalPaymentTransaction(this.transaction_id).subscribe(res => {
      this.showLoading = false;
      this.snackBarService.presentToast(res.message, 'success');
      const transaction: TransactionInterface = res.data.transaction;
      this.transaction = transaction;
    }, (err: any) => {
      this.showLoading = false;
      this.snackBarService.presentToast(err.error.message, 'danger');
    })
  }
}
