import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CountryInterface } from '@kiddy-cash/common';
import { CountryService, SnackBarService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
  standalone: false,
  selector: 'kc-admin-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss']
})
export class CountryComponent {
  displayedColumns: string[] = [
    'id', 
    'flag', 
    'name', 
    'iso2', 
    'iso3', 
    'phone_code', 
    'dollar_rate', 
    'currency_code', 
    'currency_name', 
    'currency_symbol', 
    'active',
  ];
  showLoading: boolean = true;
  staticBaseUrl: string =  environment.staticBaseUrl;
  dataSource = new MatTableDataSource<CountryInterface>([]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private countryService: CountryService,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit() {
    this.countryService.getCountries().subscribe(res => {
      const countries: CountryInterface[] = res.data.countries;
      this.dataSource.data = countries;
      this.showLoading = false;
    },
    (err: any) => {
      this.snackBarService.presentToast(err.error.message, 'danger');
      this.showLoading = false;
    })
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
}
