import { Injectable } from '@angular/core';
import { getAuth, onAuthStateChanged, onIdTokenChanged, signOut, User } from '@angular/fire/auth';
import { BehaviorSubject } from 'rxjs';
import { RolesEnum } from '@kiddy-cash/common';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public _$user = new BehaviorSubject<User | null>(null);
  public _$token = new BehaviorSubject<string | null>(null);
  public _$user_roles = new BehaviorSubject<RolesEnum[]>([]);

  constructor() { }

  async setUp(){
    const auth = getAuth();

    onAuthStateChanged(auth, async (user: User | null) => {
      this.setUser(user);
      if(user) await this.processUser(user);
    });

    onIdTokenChanged(auth, async (user: User | null) => {
      this.setUser(user);
      if(user) await this.processUser(user);
    });
  }

  async processUser(user: User) {
    const idTokenResult = await user.getIdTokenResult(true);
    const token = idTokenResult.token;
    this.setToken(token);

    const claims = idTokenResult.claims;
    const user_roles: RolesEnum[] = claims['roles'] as RolesEnum[];
    if(user_roles) this.setUserRoles(user_roles);
  }

  async refreshUserToken() {
    const user = this._$user.getValue();
    if(user) await this.processUser(user);
  }

  setUser(user: User | null) {
    this._$user.next(user);
  }

  setToken(token: string) {
    this._$token.next(token);
  }

  setUserRoles(roles: RolesEnum[]) {
    if(JSON.stringify(this._$user_roles.getValue()) !== JSON.stringify(roles)) this._$user_roles.next(roles);
  }

  async logout() {
    const auth = getAuth();

    return await signOut(auth);
  }

}
