<h1 class="page-title">Administrators
    <button mat-fab>
        <mat-icon>person_add</mat-icon>
    </button>
</h1>

<div *ngIf="showLoading">
    <div>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>        
</div>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="photo">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let admin">
            <img height="40" width="40" referrerpolicy="no-referrer" class="avatar-thumbnail" src="{{ admin.photoURL }}" />
        </td>
    </ng-container>
    
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let admin"> {{ admin.displayName }} </td>
    </ng-container>
    
    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let admin"> {{ admin.email }} </td>
    </ng-container>

    <ng-container matColumnDef="roles">
        <th mat-header-cell *matHeaderCellDef> Roles </th>
        <td mat-cell *matCellDef="let admin"> {{ admin.customClaims?.roles.toString() }} </td>
    </ng-container>

    <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef> Created </th>
        <td mat-cell *matCellDef="let admin"> {{ admin.metadata.creationTime | date : "mediumDate" }} </td>
    </ng-container>
    
    <ng-container matColumnDef="lastSignedIn">
        <th mat-header-cell *matHeaderCellDef> Last Signed In </th>
        <td mat-cell *matCellDef="let admin"> {{ admin.metadata.lastSignInTime | date : "mediumDate" }} </td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let admin; columns: displayedColumns;" [routerLink]="['/admin', admin.uid]"></tr>
</table>

<mat-paginator [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
    aria-label="Select page of periodic elements">
</mat-paginator>