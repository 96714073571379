<mat-tab-group>
    @for (userRole of userRoles; track userRole) {
        <mat-tab label="{{ userRole }}"> 

          <mat-card class="notification-card" appearance="outlined" *ngFor="let notification of getRoleNotifications(userRole)" [routerLink]="[notification.relativeUrl]">
            <mat-card-header>
              <div mat-card-avatar class="notification-header-image" style="background-image: url('{{notification.image}}')"></div>
              <mat-card-title>{{ notification.title }}</mat-card-title>
              <mat-card-subtitle>{{ notification.time | date : "medium" }}</mat-card-subtitle>
            </mat-card-header>

            <mat-card-content>
              <p *ngIf="userRole !== RolesEnum.SystemAdmin">
                {{ notification.body}}
              </p>
              <pre *ngIf="userRole === RolesEnum.SystemAdmin">
                {{ JSONParse(notification.body) | json }}
              </pre>
            </mat-card-content>
          </mat-card>

        </mat-tab>
    }
</mat-tab-group>