import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StockInterface } from '@kiddy-cash/common';
import { StockService, SnackBarService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
  standalone: false,
  selector: 'kc-admin-stock-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class StockViewComponent {
  public stock_id!: number;
  stock!: StockInterface;
  showLoading: boolean = true;
  staticBaseUrl: string =  environment.staticBaseUrl;

  constructor(
    private activatedRoute: ActivatedRoute,
    private stockService: StockService,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit() {
    this.stock_id = Number(this.activatedRoute.snapshot.paramMap.get('stock_id') as string);

    this.stockService.getStock(this.stock_id).subscribe(res => {
      const stock: StockInterface = res.data.stock;
      this.stock = stock;
      this.showLoading = false;
    },
    (err: any) => {
      this.snackBarService.presentToast(err.error.message, 'danger');
      this.showLoading = false;
    })
  }
}
