import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(private http: HttpClient) { }

  findTransactions(data: any) {
    return this.http.get<any>(`${environment.apiBaseUrl}/transaction`, { params: data });
  }

  getTransaction(transaction_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/transaction/${transaction_id}`);
  }

  updateTransaction(transaction_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/transaction/${transaction_id}`, data);
  }

  finalizeInternalPaymentTransaction(transaction_id: string) {
    return this.http.post<any>(`${environment.apiBaseUrl}/transaction/${transaction_id}/finalize`, undefined);
  }

}
