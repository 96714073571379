<h1 class="page-title">Transactions
    <button mat-fab>
        <mat-icon>person_add</mat-icon>
    </button>
</h1>

<div *ngIf="showLoading">
    <div>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>        
</div>

<mat-accordion class="expansion-headers-align">
    <mat-expansion-panel [expanded]="step() === 0" (opened)="setStep(0)" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title> Query Data </mat-panel-title>
        <mat-panel-description>
          Fill in the form
          <mat-icon>search</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
  
        <form [formGroup]="transactionQueryForm" (ngSubmit)="submitQuery(transactionQueryForm.value)">
            <mat-form-field>
                <mat-label>From Account Number</mat-label>
                <input formControlName="$from_account.number$" matInput>
            </mat-form-field>
            <mat-form-field>
                <mat-label>From Account Type</mat-label>
                <mat-select formControlName="$from_account.type$" >
                    <mat-option *ngFor="let account_type of AccountTypeEnumValues" [value]="account_type">{{ account_type }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>To Account Number</mat-label>
                <input formControlName="$to_account.number$" matInput>
            </mat-form-field>
            <mat-form-field>
                <mat-label>To Account Type</mat-label>
                <mat-select formControlName="$to_account.type$" >
                    <mat-option *ngFor="let account_type of AccountTypeEnumValues" [value]="account_type">{{ account_type }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Status</mat-label>
                <mat-select formControlName="status" >
                    <mat-option *ngFor="let status of TransactionStatusEnumValues" [value]="status">{{ status }}</mat-option>
                </mat-select>
            </mat-form-field>

            <br>

            <button mat-raised-button color="accent" [disabled]="showLoading">Query</button>
        </form>
    </mat-expansion-panel>
  
    <mat-expansion-panel [expanded]="step() === 1" (opened)="setStep(1)" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title> Search Results </mat-panel-title>
            <mat-panel-description>
                A Tabular Representation
                <mat-icon>database_search</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
  
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> Transaction ID </th>
                <td class="routable-cell" mat-cell *matCellDef="let transaction" [routerLink]="['/transaction', transaction.id]"> {{ transaction.id }}</td>
            </ng-container>

            <ng-container matColumnDef="from_account_number">
                <th mat-header-cell *matHeaderCellDef> From Account No.</th>
                <td class="routable-cell" mat-cell *matCellDef="let transaction" [routerLink]="['/account', transaction.from_account.id]"> {{ transaction.from_account.number }}</td>
            </ng-container>

            <ng-container matColumnDef="from_account_name">
                <th mat-header-cell *matHeaderCellDef> From Account Name</th>
                <td class="routable-cell" mat-cell *matCellDef="let transaction" [routerLink]="['/account', transaction.from_account.id]"> {{ transaction.from_account.name }}</td>
            </ng-container>

            <ng-container matColumnDef="from_account_type">
                <th mat-header-cell *matHeaderCellDef> From Account Type</th>
                <td mat-cell *matCellDef="let transaction"> {{ transaction.from_account.type }}</td>
            </ng-container>

            <ng-container matColumnDef="to_account_number">
                <th mat-header-cell *matHeaderCellDef> To Account No.</th>
                <td class="routable-cell" mat-cell *matCellDef="let transaction" [routerLink]="['/account', transaction.to_account.id]"> {{ transaction.to_account.number }}</td>
            </ng-container>

            <ng-container matColumnDef="to_account_name">
                <th mat-header-cell *matHeaderCellDef> To Account Name</th>
                <td class="routable-cell" mat-cell *matCellDef="let transaction" [routerLink]="['/account', transaction.to_account.id]"> {{ transaction.to_account.name }}</td>
            </ng-container>

            <ng-container matColumnDef="to_account_type">
                <th mat-header-cell *matHeaderCellDef> To Account Type</th>
                <td mat-cell *matCellDef="let transaction"> {{ transaction.to_account.type }}</td>
            </ng-container>

            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef> Amount </th>
                <td mat-cell *matCellDef="let transaction"> {{ transaction.amount | number }}</td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef> Type </th>
                <td mat-cell *matCellDef="let transaction"> {{ transaction.type }}</td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let transaction"> {{ transaction.status }}</td>
            </ng-container>

            <ng-container matColumnDef="purpose">
                <th mat-header-cell *matHeaderCellDef> purpose </th>
                <td mat-cell *matCellDef="let transaction"> {{ transaction.purpose }}</td>
            </ng-container>

            <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef> Created On </th>
                <td mat-cell *matCellDef="let transaction"> {{ transaction.creation_date | date : "mediumDate" }} </td>
            </ng-container>

            <ng-container matColumnDef="updated">
                <th mat-header-cell *matHeaderCellDef> Updated On </th>
                <td mat-cell *matCellDef="let transaction"> {{ transaction.updated_on | date : "mediumDate" }} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let transaction; columns: displayedColumns;"></tr>
        </table>
        
        <mat-paginator [pageSizeOptions]="[5, 10, 20]"
            showFirstLastButtons
            aria-label="Select page of periodic elements">
        </mat-paginator>

        <mat-action-row>
            <button mat-button (click)="prevStep()">Previous</button>
            <button mat-button (click)="nextStep()">Next</button>
        </mat-action-row>
    </mat-expansion-panel>
  
    <mat-expansion-panel [expanded]="step() === 2" (opened)="setStep(2)" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title> Advanced Search Results </mat-panel-title>
            <mat-panel-description>
                The Raw Data as JSON
                <mat-icon>search_insights</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <ng-container *ngIf="transactions.length == 0">
            <p>No Results Found</p>
            <button mat-stroked-button (click)="setStep(0)">Back to Search</button>
        </ng-container>

        <ng-container *ngIf="transactions.length > 0">
            <mat-nav-list>
                <mat-list-item [routerLink]="['/transaction', transactions[selectedIndex].id]">
                    <span matListItemLine>ID : {{ transactions[selectedIndex].id }}</span> 
                    <span matListItemLine>From Account No. : {{ transactions[selectedIndex].from_account.number }}</span> 
                    <span matListItemLine>From Account Name : {{ transactions[selectedIndex].from_account.name }}</span> 
                    <span matListItemLine>To Account No. : {{ transactions[selectedIndex].to_account.number }}</span> 
                    <span matListItemLine>To Account Name : {{ transactions[selectedIndex].to_account.name }}</span> 
                </mat-list-item>
            </mat-nav-list>
        </ng-container>
        
        <ng-container *ngIf="transactions.length">
            <data-viewer [data]="transactions" (selectedIndex)="onSelectedIndex($event)"></data-viewer>
        </ng-container>

        <mat-action-row>
            <button mat-button (click)="prevStep()">Previous</button>
            <button mat-button (click)="nextStep()">End</button>
        </mat-action-row>
    </mat-expansion-panel>
</mat-accordion>
