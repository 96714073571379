import { Injectable } from '@angular/core';
import { User } from '@angular/fire/auth';
import { Messaging, getMessaging, getToken, deleteToken, onMessage } from '@angular/fire/messaging';
import { filter, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { FirebaseService } from './firebase.service';
import { FunctionsService } from './functions.service';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {
  user: User | null | undefined;
  messaging: Messaging = getMessaging();

  constructor(
    private authService: AuthService,
    private fibaseService: FirebaseService,
    private functionsService: FunctionsService,
  ) { }

  public async setUp() {
    this.listenWeb()

    this.authService._$user
      .pipe(filter(user => user !== null && user !== undefined), take(1))
      .subscribe(async (user: User | null | undefined) => {
      this.user = user;

      if(user){
        if (Notification.permission !== "denied" && Notification.permission !== "granted") {
          this.registerNotificationsWeb(); 
        } else if(Notification.permission === "granted") {
          // already granted, let's make sure user is subscribed
          this.registerNotificationsWeb();
        }
      }
    });
  }

  registerNotificationsWeb() {
    getToken(this.messaging, 
     { vapidKey: environment.fcm.vapidKey}).then(
       async (currentToken) => {
         if (currentToken) {      
          this.subscribeToPushNotifications();
         } else {
           console.warn('No registration token available. Request permission to generate one.');
         }
     }).catch((err) => {
        console.error('An error occurred while retrieving token. ', err);
    });
  }

  subscribeToPushNotifications = async () => {
    await this.functionsService.pushNotificationsSubscribe();
  }

  listenWeb() {
    onMessage(this.messaging, (payload) => {
      const notificationTitle = payload?.notification?.title || 'Admin Kiddy Cash';
      const notificationOptions = {
        body: payload?.notification?.body,
      };

      new Notification(notificationTitle, notificationOptions);
    });
  }

  unregisterToken = async () => {
    try {
      await deleteToken(this.messaging);
    } catch (err) {
      console.error(err)
    }
  }

}
