import { Component } from '@angular/core';

@Component({
  standalone: false,
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrl: './view.component.scss'
})
export class KnowYourBusinessViewComponent {

}
