import { Component } from '@angular/core';

@Component({
  standalone: false,
  selector: 'kc-admin-kiddy',
  templateUrl: './kiddy.component.html',
  styleUrls: ['./kiddy.component.scss']
})
export class KiddyComponent {

}
