import { inject, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Analytics, logEvent, setUserId, setCurrentScreen } from '@angular/fire/analytics';
import { Performance, trace } from '@angular/fire/performance';
import { User } from '@angular/fire/auth';
import { FunctionsService } from './functions.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  user: User | null | undefined;

  private webAnalytics: Analytics = inject(Analytics);
  private webPerformance: Performance = inject(Performance);

  constructor(
    private authService: AuthService,
    private functionsService: FunctionsService,
  ) { }

  public async setUp() {
    this.authService._$user.subscribe((user: User | null | undefined) => {
      this.user = user;
    });
  }

  // Firebase Web Methods
  private webAnalyticsCrash = async () => {

  };

  private webPerformanceStartTrace = async (traceName: string) => {
    const performanceTrace = trace(this.webPerformance, traceName);
    performanceTrace.start()
  };

  private webPerformancePutMetric = async (traceName: string, metricName: string, metricValue: number ) => {
    const performanceTrace = trace(this.webPerformance, traceName);
    performanceTrace.putMetric(metricName, metricValue);
  };

  private webPerformanceStopTrace = async (traceName: string) => {
    const performanceTrace = trace(this.webPerformance, traceName);
    performanceTrace.stop()
  };

  private webAnalyticsSetUserId = async (user_id: string) => {
    setUserId(this.webAnalytics, user_id);
  };
  
  private webAnalyticsSetUserProperty = async (key: string, value: string) => {

  };
  
  private webAnalyticsSetCurrentScreen = async (screenName: string) => {
    setCurrentScreen(this.webAnalytics, screenName);
  };
  
  private webAnalyticsLogEvent = async (name: string, params: any) => {
    logEvent(this.webAnalytics, name, params);
  };
  
}
