import { Component, OnInit, OnDestroy } from '@angular/core';
import { Auth, signOut, User, GoogleAuthProvider, onAuthStateChanged, getAuth } from '@angular/fire/auth';
import  * as firebaseui from 'firebaseui'

@Component({
  standalone: false,
  selector: 'kc-admin-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  public user: User | null | undefined = undefined;

  showLoading: boolean = true;

  constructor(private auth: Auth) {

  }

  ngOnInit(): void { 
    const auth = getAuth();
    onAuthStateChanged(auth, (user: User | null) => {
      this.user = user
      if (!user) setTimeout(() => {this.showFirebaseUI()}, 250)
    });
  }

  async showFirebaseUI() {
    const _this = this

    const ui = firebaseui.auth.AuthUI.getInstance() ||  new firebaseui.auth.AuthUI(getAuth());
    ui.start('#firebaseui-auth-container', {
      callbacks: {
        signInSuccessWithAuthResult: function(authResult, redirectUrl) {
          // User successfully signed in.
          // Return type determines whether we continue the redirect automatically
          // or whether we leave that to developer to handle.
          return true;
        },
        signInFailure: function(error) {
          console.error('Sign in error: ', error);
        },
        uiShown: function() {
          // The widget is rendered.
          // Hide the loader.
          _this.showLoading = false;
        }
      },
      signInFlow: 'popup',
      signInOptions: [
        {
          provider: GoogleAuthProvider.PROVIDER_ID,
        },
      ],
    });  
  }

  async logout() {
    return await signOut(this.auth);
  }

  ngOnDestroy(): void {

  }

}
