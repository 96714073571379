import { Component } from '@angular/core';

@Component({
  standalone: false,
  selector: 'kc-admin-payment-card',
  templateUrl: './payment-card.component.html',
  styleUrls: ['./payment-card.component.scss']
})
export class PaymentCardComponent {

}
