import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { IntegrationService, SnackBarService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
  standalone: false,
  selector: 'kc-admin-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.scss']
})
export class IntegrationComponent {
  displayedColumns: string[] = [
    'id', 
    'name', 
    'description', 
    'service', 
    'name', 
    'active',
    'createdAt',
    'updatedAt',
  ];
  showLoading: boolean = true;
  staticBaseUrl: string =  environment.staticBaseUrl;
  dataSource = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private integrationService: IntegrationService,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit() {
    this.integrationService.findIntegrations({}).subscribe(res => {
      const integrations: any[] = res.data.integrations;
      this.dataSource.data = integrations;
      this.showLoading = false;
    },
    (err: any) => {
      this.snackBarService.presentToast(err.error.message, 'danger');
      this.showLoading = false;
    })
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
}
