import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';
import { AccountComponent } from './pages/account/account.component';
import { AccountViewComponent } from './pages/account/view/view.component';
import { AdminComponent } from './pages/admin/admin.component';
import { AdminViewComponent } from './pages/admin/view/view.component';
import { AllowanceComponent } from './pages/allowance/allowance.component';
import { AllowanceViewComponent } from './pages/allowance/view/view.component';
import { BusinessComponent } from './pages/business/business.component';
import { BusinessViewComponent } from './pages/business/view/view.component';
import { CountryComponent } from './pages/country/country.component';
import { CountryViewComponent } from './pages/country/view/view.component';
import { FamilyComponent } from './pages/family/family.component';
import { FamilyViewComponent } from './pages/family/view/view.component';
import { GoalComponent } from './pages/goal/goal.component';
import { GoalViewComponent } from './pages/goal/view/view.component';
import { InvestmentComponent } from './pages/investment/investment.component';
import { InvestmentViewComponent } from './pages/investment/view/view.component';
import { InternalPaymentsComponent } from './pages/internal-payments/internal-payments.component';
import { InternalPaymentsCreateComponent } from './pages/internal-payments/create/create.component';
import { InternalPaymentsTransactComponent } from './pages/internal-payments/transact/transact.component';
import { IntegrationComponent } from './pages/integration/integration.component';
import { IntegrationViewComponent } from './pages/integration/view/view.component';
import { KiddyComponent } from './pages/kiddy/kiddy.component';
import { KiddyViewComponent } from './pages/kiddy/view/view.component';
import { KnowYourBusinessComponent } from './pages/know-your-business/know-your-business.component';
import { KnowYourCustomerComponent } from './pages/know-your-customer/know-your-customer.component';
import { KnowYourSchoolComponent } from './pages/know-your-school/know-your-school.component';
import { LoanComponent } from './pages/loan/loan.component';
import { LoanViewComponent } from './pages/loan/view/view.component';
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { PaymentCardComponent } from './pages/payment-card/payment-card.component';
import { PaymentCardViewComponent } from './pages/payment-card/view/view.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ProfileViewComponent } from './pages/profile/view/view.component';
import { StockComponent } from './pages/stock/stock.component';
import { StockViewComponent } from './pages/stock/view/view.component';
import { TaskComponent } from './pages/task/task.component';
import { TaskViewComponent } from './pages/task/view/view.component';
import { TransactionComponent } from './pages/transaction/transaction.component';
import { TransactionViewComponent } from './pages/transaction/view/view.component';
import { UserComponent } from './pages/user/user.component';
import { UserViewComponent } from './pages/user/view/view.component';
import { KnowYourBusinessViewComponent } from './pages/know-your-business/view/view.component';
import { KnowYourCustomerViewComponent } from './pages/know-your-customer/view/view.component';
import { KnowYourSchoolViewComponent } from './pages/know-your-school/view/view.component';
import { SchoolComponent } from './pages/school/school.component';
import { SchoolViewComponent } from './pages/school/view/view.component';

const routes: Routes = [
  { 
    path: '', 
    component: LoginComponent,
  },
  {
    path:'admin', 
    canActivate: [authGuard],
    component: AdminComponent,
  },
  {
    path:'admin/:admin_id', 
    canActivate: [authGuard],
    component: AdminViewComponent,
  },
  {
    path:'account', 
    canActivate: [authGuard],
    component: AccountComponent,
  },
  {
    path:'account/:account_id', 
    canActivate: [authGuard],
    component: AccountViewComponent,
  },
  {
    path:'allowance', 
    canActivate: [authGuard],
    component: AllowanceComponent,
  },
  {
    path:'allowance/:allowance_id', 
    canActivate: [authGuard],
    component: AllowanceViewComponent,
  },
  {
    path:'business', 
    canActivate: [authGuard],
    component: BusinessComponent,
  },
  {
    path:'business/:business_id', 
    canActivate: [authGuard],
    component: BusinessViewComponent,
  },
  {
    path:'country', 
    canActivate: [authGuard],
    component: CountryComponent,
  },
  {
    path:'country/:country_id', 
    canActivate: [authGuard],
    component: CountryViewComponent,
  },
  {
    path:'family', 
    canActivate: [authGuard],
    component: FamilyComponent,
  },
  {
    path:'family/:family_id', 
    canActivate: [authGuard],
    component: FamilyViewComponent,
  },
  {
    path:'goal', 
    canActivate: [authGuard],
    component: GoalComponent,
  },
  {
    path:'goal/:goal_id', 
    canActivate: [authGuard],
    component: GoalViewComponent,
  },
  {
    path:'integration', 
    canActivate: [authGuard],
    component: IntegrationComponent,
  },
  {
    path:'integration/:integration_id', 
    canActivate: [authGuard],
    component: IntegrationViewComponent,
  },
  {
    path:'internal-payments', 
    canActivate: [authGuard],
    component: InternalPaymentsComponent,
  },
  {
    path:'internal-payments/create', 
    canActivate: [authGuard],
    component: InternalPaymentsCreateComponent,
  },
  {
    path:'internal-payments/:account_id/transact', 
    canActivate: [authGuard],
    component: InternalPaymentsTransactComponent,
  },
  {
    path:'investment', 
    canActivate: [authGuard],
    component: InvestmentComponent,
  },
  {
    path:'investment/:investment_id', 
    canActivate: [authGuard],
    component: InvestmentViewComponent,
  },
  {
    path:'kiddy', 
    canActivate: [authGuard],
    component: KiddyComponent,
  },
  {
    path:'kiddy/:kiddy_id', 
    canActivate: [authGuard],
    component: KiddyViewComponent,
  },
  {
    path:'loan', 
    canActivate: [authGuard],
    component: LoanComponent,
  },
  {
    path:'know-your-business', 
    canActivate: [authGuard],
    component: KnowYourBusinessComponent,
  },
  {
    path:'know-your-business/:kyb_id', 
    canActivate: [authGuard],
    component: KnowYourBusinessViewComponent,
  },
  {
    path:'know-your-customer', 
    canActivate: [authGuard],
    component: KnowYourCustomerComponent,
  },
  {
    path:'know-your-customer/:kyc_id', 
    canActivate: [authGuard],
    component: KnowYourCustomerViewComponent,
  },
  {
    path:'know-your-school', 
    canActivate: [authGuard],
    component: KnowYourSchoolComponent,
  },
  {
    path:'know-your-school/:kys_id', 
    canActivate: [authGuard],
    component: KnowYourSchoolViewComponent,
  },
  {
    path:'loan/:loan_id', 
    canActivate: [authGuard],
    component: LoanViewComponent,
  },
  {
    path:'payment-card', 
    canActivate: [authGuard],
    component: PaymentCardComponent,
  },
  {
    path:'payment-card/:payment_card_id', 
    canActivate: [authGuard],
    component: PaymentCardViewComponent,
  },
  {
    path:'profile', 
    canActivate: [authGuard],
    component: ProfileComponent,
  },
  {
    path:'profile/:profile_id', 
    canActivate: [authGuard],
    component: ProfileViewComponent,
  },
  {
    path:'school', 
    canActivate: [authGuard],
    component: SchoolComponent,
  },
  {
    path:'school/:stock_id', 
    canActivate: [authGuard],
    component: SchoolViewComponent,
  },
  {
    path:'stock', 
    canActivate: [authGuard],
    component: StockComponent,
  },
  {
    path:'stock/:stock_id', 
    canActivate: [authGuard],
    component: StockViewComponent,
  },
  {
    path:'task', 
    canActivate: [authGuard],
    component: TaskComponent,
  },
  {
    path:'task/:task_id', 
    canActivate: [authGuard],
    component: TaskViewComponent,
  },
  {
    path:'transaction', 
    canActivate: [authGuard],
    component: TransactionComponent,
  },
  {
    path:'transaction/:transaction_id', 
    canActivate: [authGuard],
    component: TransactionViewComponent,
  },
  {
    path:'user', 
    canActivate: [authGuard],
    component: UserComponent,
  },
  {
    path:'user/:user_id', 
    canActivate: [authGuard],
    component: UserViewComponent,
  },
  {
    path:'notifications', 
    canActivate: [authGuard],
    component: NotificationsComponent,
  },
  { 
    path: '**', 
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
