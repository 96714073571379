<h1 class="page-title">Users
    <button mat-fab>
        <mat-icon>person_add</mat-icon>
    </button>
</h1>

<div *ngIf="showLoading">
    <div>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>        
</div>

<mat-accordion class="expansion-headers-align">
    <mat-expansion-panel [expanded]="step() === 0" (opened)="setStep(0)" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title> Query Data </mat-panel-title>
        <mat-panel-description>
          Fill in the form
          <mat-icon>search</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
  
        <form [formGroup]="userQueryForm" (ngSubmit)="submitQuery(userQueryForm.value)">
            <mat-form-field>
                <mat-label>Email</mat-label>
                <input formControlName="email" matInput>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Phone</mat-label>
                <input formControlName="phone" matInput>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Status</mat-label>
                <mat-select formControlName="status" >
                    <mat-option *ngFor="let status of UserStatusEnumValues" [value]="status">{{ status }}</mat-option>
                </mat-select>
            </mat-form-field>

            <br>

            <button mat-raised-button color="accent" [disabled]="showLoading">Query</button>
        </form>
    </mat-expansion-panel>
  
    <mat-expansion-panel [expanded]="step() === 1" (opened)="setStep(1)" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title> Search Results </mat-panel-title>
            <mat-panel-description>
                A Tabular Representation
                <mat-icon>database_search</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
  
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="photo">
                <th mat-header-cell *matHeaderCellDef>  </th>
                <td mat-cell *matCellDef="let app_user">
                    <img height="40" width="40" class="avatar-thumbnail" alt="avatar" src="{{ app_user.profile?.avatarversion ? ( staticBaseUrl + '/profiles/' + app_user.profile?.id + '/avatars/' + app_user.profile?.avatarversion + '.jpeg' ) : ( staticBaseUrl + '/defaults/avatar.svg') }}"/>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let app_user"> {{ app_user.profile.firstname }} {{ app_user.profile.lastname }} </td>
            </ng-container>

            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef> Phone </th>
                <td mat-cell *matCellDef="let app_user"> {{ app_user.phone }} </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef> Email </th>
                <td mat-cell *matCellDef="let app_user"> {{ app_user.email }} </td>
            </ng-container>

            <ng-container matColumnDef="country">
                <th mat-header-cell *matHeaderCellDef> Country </th>
                <td mat-cell *matCellDef="let app_user"> {{ app_user.profile.country?.name }} </td>
            </ng-container>

            <ng-container matColumnDef="dob">
                <th mat-header-cell *matHeaderCellDef> Date of Birth </th>
                <td mat-cell *matCellDef="let app_user"> {{ app_user.profile.dob | date : "mediumDate" }} </td>
            </ng-container>

            <ng-container matColumnDef="genderidentity">
                <th mat-header-cell *matHeaderCellDef> Gender </th>
                <td mat-cell *matCellDef="let app_user"> {{ app_user.profile.genderidentity }} </td>
            </ng-container>

            <ng-container matColumnDef="roles">
                <th mat-header-cell *matHeaderCellDef> Roles </th>
                <td mat-cell *matCellDef="let app_user"> {{ getProfileRoles(app_user.profile.profile_roles) }} </td>
            </ng-container>

            <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef> Created </th>
                <td mat-cell *matCellDef="let app_user"> {{ app_user.creation_date | date : "mediumDate" }} </td>
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let app_user; columns: displayedColumns;" [routerLink]="['/user', app_user.id]"></tr>
        </table>
        
        <mat-paginator [pageSizeOptions]="[5, 10, 20]"
            showFirstLastButtons
            aria-label="Select page of periodic elements">
        </mat-paginator>

        <mat-action-row>
            <button mat-button (click)="prevStep()">Previous</button>
            <button mat-button (click)="nextStep()">Next</button>
        </mat-action-row>
    </mat-expansion-panel>
  
    <mat-expansion-panel [expanded]="step() === 2" (opened)="setStep(2)" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title> Advanced Search Results </mat-panel-title>
            <mat-panel-description>
                The Raw Data as JSON
                <mat-icon>search_insights</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <ng-container *ngIf="app_users.length == 0">
            <p>No Results Found</p>
            <button mat-stroked-button (click)="setStep(0)">Back to Search</button>
        </ng-container>

        <ng-container *ngIf="app_users.length > 0">
            <mat-nav-list>
                <mat-list-item [routerLink]="['/user', app_users[selectedIndex].id]">
                    <img matListItemAvatar alt="avatar" src="{{ app_users[selectedIndex].profile.avatarversion ? ( staticBaseUrl + '/profiles/' + app_users[selectedIndex].profile.id + '/avatars/' + app_users[selectedIndex].profile.avatarversion + '.jpeg' ) : ( staticBaseUrl + '/defaults/avatar.svg') }}"/>
                    <span matListItemLine>ID : {{ app_users[selectedIndex].id }}</span> 
                    <span matListItemLine>Phone : {{ app_users[selectedIndex].phone }}</span> 
                    <span matListItemLine>Email : {{ app_users[selectedIndex].email }}</span>
                </mat-list-item>
            </mat-nav-list>
        </ng-container>
        
        <ng-container *ngIf="app_users.length">
            <data-viewer [data]="app_users" (selectedIndex)="onSelectedIndex($event)"></data-viewer>
        </ng-container>

        <mat-action-row>
            <button mat-button (click)="prevStep()">Previous</button>
            <button mat-button (click)="nextStep()">End</button>
        </mat-action-row>
    </mat-expansion-panel>
</mat-accordion>
