import { Component } from '@angular/core';

@Component({
  standalone: false,
  selector: 'kc-admin-task-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class TaskViewComponent {

}
