import { Component } from '@angular/core';
import { AccountTypeEnum } from '@kiddy-cash/common';

@Component({
  standalone: false,
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent {

}
