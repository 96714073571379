import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountInterface, TransactionInterface } from '@kiddy-cash/common';
import { InternalPaymentsService, SnackBarService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
  standalone: false,
  selector: 'app-transact',
  templateUrl: './transact.component.html',
  styleUrls: ['./transact.component.scss']
})
export class InternalPaymentsTransactComponent {
  public account_id!: string;
  account: AccountInterface | undefined;
  showLoading: boolean = true;
  staticBaseUrl: string =  environment.staticBaseUrl;

  internalPaymentAccountTransactionForm: FormGroup = this.formBuilder.group({
    'integration_id': ['',
      [
        Validators.required,
      ],
    ],
    'to_account_number': ['',
      [
        Validators.required,
      ],
    ],
    'amount': ['',
      [
        Validators.required,
      ],
    ]
  })

  constructor(
    private activatedRoute: ActivatedRoute,
    private internalPaymentsService: InternalPaymentsService,
    private snackBarService: SnackBarService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit() {
    this.account_id = this.activatedRoute.snapshot.paramMap.get('account_id') as string;

    this.internalPaymentsService.getInternalPayments(this.account_id).subscribe(res => {
      const account: AccountInterface = res.data.account;
      this.account = account;
      this.showLoading = false;
      this.snackBarService.presentToast('The transaction has been saved, you can attempt to complete it later', 'secondary');
    },
    (err: any) => {
      this.snackBarService.presentToast(err.error.message, 'danger');
      this.showLoading = false;
    })
  }

  submitForm(data: any){
    if(!this.internalPaymentAccountTransactionForm.valid) return;

    this.showLoading = true;
    this.internalPaymentsService.transactInternalPaymentsWithIntegration(this.account_id, data).subscribe(res => {
      this.showLoading = false;
      this.snackBarService.presentToast(res.message, 'success');
      const transaction: TransactionInterface = res.data.transaction;
      this.router.navigate(['/transaction', transaction.id]);
    }, (err: any) => {
      this.showLoading = false;
      this.snackBarService.presentToast(err.error.message, 'danger');
      const transaction = err.error.data?.transaction;
      if(transaction && transaction.id) {
        this.router.navigate(['/transaction', transaction.id]);
        this.snackBarService.presentToast('The transaction has been saved, you can attempt to complete it later', 'secondary');
      }
    })
  }

}
