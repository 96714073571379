import { Component, signal, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AccountTypeEnum, TransactionInterface, TransactionStatusEnum } from '@kiddy-cash/common';
import { TransactionService, SnackBarService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
  standalone: false,
  selector: 'kc-admin-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss']
})
export class TransactionComponent {
  transactions: TransactionInterface[] = [];
  TransactionStatusEnumValues = Object.values(TransactionStatusEnum);
  AccountTypeEnumValues = Object.values(AccountTypeEnum);
  staticBaseUrl: string =  environment.staticBaseUrl;
  showLoading: boolean = false;

  transactionQueryForm: FormGroup = this.formBuilder.group({
    '$from_account.number$': '',
    '$from_account.type$': '',
    '$to_account.number$': '',
    '$to_account.type$': '',
    status: '',
  })

  displayedColumns: string[] = [
    'id', 
    'from_account_number', 
    'from_account_name', 
    'from_account_type', 
    'to_account_number', 
    'to_account_name', 
    'to_account_type', 
    'amount',
    'type',
    'status', 
    'purpose', 
    'created', 
    'updated', 
  ];

  dataSource = new MatTableDataSource<TransactionInterface>([]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  selectedIndex: number = 0;

  step = signal(0);

  setStep(index: number) {
    this.step.set(index);
  }

  nextStep() {
    this.step.update(i => i + 1);
  }

  prevStep() {
    this.step.update(i => i - 1);
  }

  constructor(
    private transactionService: TransactionService,
    private snackBarService: SnackBarService,
    private formBuilder: FormBuilder,
  ) {

   }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  submitQuery(formData: any){
    const data = structuredClone(formData)
    Object.keys(data).forEach((key) => (data[key] === null || data[key] === '') && delete data[key]);
    this.queryForTransactions(data)
  }

  queryForTransactions(data: any) {
    this.showLoading = true;
    this.transactions = []
    this.selectedIndex = 0;

    this.transactionService.findTransactions(data).subscribe(res => {
      const transactions: TransactionInterface[] = res.data.transactions;
      this.transactions = transactions;
      this.dataSource.data = transactions;
      transactions.length ? this.setStep(1) : this.setStep(2);
      this.showLoading = false;
    },(err: any) => {
      this.showLoading = false;
      this.snackBarService.presentToast(err.error.message, 'danger');
    })
  }

  onSelectedIndex(index: number) {
    this.selectedIndex = index;
  }

}
