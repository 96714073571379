import { Component, signal, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { KnowYourCustomerInterface, ProfileRoleInterface, RolesEnum } from '@kiddy-cash/common';
import { KnowYourCustomerService, SnackBarService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
  standalone: false,
  selector: 'app-know-your-customer',
  templateUrl: './know-your-customer.component.html',
  styleUrl: './know-your-customer.component.scss'
})
export class KnowYourCustomerComponent {
  kycs: KnowYourCustomerInterface[] = [];
  RolesEnumValues = Object.values(RolesEnum);
  staticBaseUrl: string =  environment.staticBaseUrl;
  showLoading: boolean = false;

  kycQueryForm: FormGroup = this.formBuilder.group({
    passed: '',
    phone: '',
    email: ['', Validators.email],
    profile_id: '',
    '$profile.user.phone$': '',
    '$profile.user.email$': ['', Validators.email],
    '$profile.profile_roles.role$': '',
  })

  displayedColumns: string[] = [
    'profile_photo', 
    'passed',
    'phone',
    'email', 
    'profile_name', 
    'profile_user_phone',
    'profile_user_email', 
    'profile_country', 
    'profile_dob', 
    'profile_genderidentity', 
    'profile_roles', 
    'action', 
  ];

  dataSource = new MatTableDataSource<KnowYourCustomerInterface>([]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  selectedIndex: number = 0;

  step = signal(0);

  setStep(index: number) {
    this.step.set(index);
  }

  nextStep() {
    this.step.update(i => i + 1);
  }

  prevStep() {
    this.step.update(i => i - 1);
  }

  queryData: object = {}
 
  constructor(
    private kycService: KnowYourCustomerService,
    private snackBarService: SnackBarService,
    private formBuilder: FormBuilder,
  ) {

   }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  submitQuery(formData: any){
    const data = structuredClone(formData)
    Object.keys(data).forEach((key) => (data[key] === null || data[key] === '') && delete data[key]);
    this.queryForKycs(data)
  }

  queryForKycs(data: any) {
    this.showLoading = true;
    this.kycs = []
    this.selectedIndex = 0;

    this.queryData = data;

    this.kycService.queryKYCs(data).subscribe(res => {
      const kycs: KnowYourCustomerInterface[] = res.data.kycs;
      this.kycs = kycs;
      this.dataSource.data = kycs;
      kycs.length ? this.setStep(1) : this.setStep(2);
      this.showLoading = false;
    },(err: any) => {
      this.showLoading = false;
      this.snackBarService.presentToast(err.error.message, 'danger');
    })
  }

  onSelectedIndex(index: number) {
    this.selectedIndex = index;
  }

  getProfileRoles(profile_roles: ProfileRoleInterface[]): string {
    return profile_roles.flatMap(profile_role => profile_role.role.title).toString();
  }

  updateKyc(kyc_id: number, passed: boolean) {
    this.showLoading = true;
    this.kycService.updateKYC(kyc_id, { passed: passed }).subscribe(res => {
      this.snackBarService.presentToast(res.message, 'success');
      this.queryForKycs(this.queryData);
    },(err: any) => {
      this.showLoading = false;
      this.snackBarService.presentToast(err.error.message, 'danger');
    })
  }

}
