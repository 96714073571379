import { Component } from '@angular/core';
import { NotificationInterface, RolesEnum } from '@kiddy-cash/common';
import { distinctUntilChanged } from 'rxjs';
import { AuthService, FirestoreService } from 'src/app/services';

@Component({
  standalone: false,
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss'
})
export class NotificationsComponent {
  RolesEnum: typeof RolesEnum = RolesEnum;
  userRoles: RolesEnum[] = [];

  systemAdminNotifications: NotificationInterface[] = [];
  adminNotifications: NotificationInterface[] = [];
  auditorNotifications: NotificationInterface[] = [];
  analystNotifications: NotificationInterface[] = [];
  investorNotifications: NotificationInterface[] = [];
  superAdminNotifications: NotificationInterface[] = [];

  constructor(
    private authService: AuthService,
    private firestoreService: FirestoreService,
  ) { 
  }

  async ngOnInit() {
    this.authService._$user_roles
        .pipe(distinctUntilChanged())
        .subscribe((userRoles: RolesEnum[]) => {
        this.userRoles = userRoles;
    });

    this.firestoreService._$systemAdmin.subscribe((notifications: NotificationInterface[]) => {
      this.systemAdminNotifications = notifications;
    });
    this.firestoreService._$admin.subscribe((notifications: NotificationInterface[]) => {
      this.adminNotifications = notifications;
    });
    this.firestoreService._$auditor.subscribe((notifications: NotificationInterface[]) => {
      this.auditorNotifications = notifications;
    });
    this.firestoreService._$analyst.subscribe((notifications: NotificationInterface[]) => {
      this.analystNotifications = notifications;
    });
    this.firestoreService._$investor.subscribe((notifications: NotificationInterface[]) => {
      this.investorNotifications = notifications;
    });
    this.firestoreService._$superAdmin.subscribe((notifications: NotificationInterface[]) => {
      this.superAdminNotifications = notifications;
    });
  }

  getRoleNotifications(role: RolesEnum): NotificationInterface[] {
    switch(role) {
      case RolesEnum.SystemAdmin:
        return this.systemAdminNotifications;
      case RolesEnum.Admin:
        return this.adminNotifications;
      case RolesEnum.Auditor:
        return this.auditorNotifications;
      case RolesEnum.Analyst:
        return this.analystNotifications;
      case RolesEnum.Investor:
        return this.investorNotifications;
      case RolesEnum.SuperAdmin:
        return this.superAdminNotifications;
      default:
        return [];
    }
  }

  JSONParse(value: any) {
    const data = JSON.parse(value)

    if(data.name === 'AxiosError'){
      data.config.data = JSON.parse(data.config.data)
    }

    return data;
  }
}
