import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RolesEnum } from '@kiddy-cash/common';
import { AdminUserService, SnackBarService } from 'src/app/services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  standalone: false,
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class AdminViewComponent {
  public admin_id!: string;
  public admin: any;
  showLoading: boolean = true;

  roles: string[] = [];

  RolesEnumValues = Object.values(RolesEnum);
  rolesList: string[] = this.RolesEnumValues.filter(role => (
      role === RolesEnum.Admin || 
      role === RolesEnum.Analyst || 
      role === RolesEnum.Auditor || 
      role === RolesEnum.Investor || 
      role === RolesEnum.SuperAdmin ||
      role === RolesEnum.SystemAdmin
    )
  );

  rolesForm: FormGroup = this.formBuilder.group({
    roles: [this.roles,
      [
        Validators.required,
      ]
    ],
  })

  constructor(
    private activatedRoute: ActivatedRoute,
    private adminUserService: AdminUserService,
    private snackBarService: SnackBarService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit() {
    this.admin_id = this.activatedRoute.snapshot.paramMap.get('admin_id') as string;
    this.adminUserService.getAdmin(this.admin_id).subscribe(res => {
      this.admin = res.data.admin;
      this.roles = this.admin.customClaims?.roles;
      this.showLoading = false;
    },
    (err: any) => {
      this.snackBarService.presentToast(err.error.message, 'danger');
      this.showLoading = false;
    })
  }

  setAdminUserRoles(){
    const roles = this.roles;

    const data = {
      roles: roles,
    }

    this.showLoading = true;

    this.adminUserService.setAdminUserRoles(this.admin.uid, data).subscribe(res => {
      this.snackBarService.presentToast(res.message, 'success');
      this.showLoading = false;
      this.router.navigateByUrl('/admin')
    },(err: any) => {
      this.showLoading = false;
      this.snackBarService.presentToast(err.error.message, 'danger');
    })
  }

}
