<h1 class="page-title">Admin : {{ admin?.displayName }}</h1>

<div *ngIf="showLoading">
    <div>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>        
</div>

<div>
    <form [formGroup]="rolesForm" (ngSubmit)="setAdminUserRoles()">
        <mat-form-field>
            <mat-label>Roles</mat-label>
            <mat-select [(value)]="roles" multiple>
            @for (role of rolesList; track role) {
                <mat-option [value]="role">{{ role }}</mat-option>
            }
            </mat-select>
        </mat-form-field>

        <br>
    
        <button mat-raised-button color="accent" [disabled]="showLoading">Update</button>
    </form>
</div>

<div>
    <pre>{{ admin | json }}</pre>
</div>


