import { Component } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AccountInterface } from '@kiddy-cash/common';
import { IntegrationService, InternalPaymentsService, SnackBarService } from 'src/app/services';

@Component({
  standalone: false,
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class InternalPaymentsCreateComponent {
  accounts: AccountInterface[] = [];
  integrations: any[] = [];
  showLoading: boolean = true;

  internalPaymentAccountIntegrationForm: FormGroup = this.formBuilder.group({
    'internal_account_id': '',
    'integration_id': '',
    'external_id': '',
  })

  constructor(
    private internalPaymentsService: InternalPaymentsService,
    private integrationService: IntegrationService,
    private snackBarService: SnackBarService,
    private formBuilder: FormBuilder,
  ) {

   }

  ngOnInit() {
    this.internalPaymentsService.findInternalPayments({}).subscribe(res => {
      const accounts: any[] = res.data.accounts;
      this.accounts = accounts;
      this.showLoading = false;
    },(err: any) => {
      this.showLoading = false;
      this.snackBarService.presentToast(err.error.message, 'danger');
    })

    this.integrationService.findIntegrations({}).subscribe(res => {
      const integrations: any[] = res.data.integrations;
      this.integrations = integrations;
      this.showLoading = false;
    },
    (err: any) => {
      this.snackBarService.presentToast(err.error.message, 'danger');
      this.showLoading = false;
    })
  }

  submitForm(data: any){
    this.showLoading = true;
    this.internalPaymentsService.createInternalPaymentsIntegration(data).subscribe(res => {
      this.snackBarService.presentToast(res.message, 'success');
      this.showLoading = false;
    }, (err: any) => {
      this.snackBarService.presentToast(err.error.message, 'danger');
      this.showLoading = false;
    })
  }

}
