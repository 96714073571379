<h1 class="page-title">Transaction
    <button mat-button *ngIf="transaction?.status === TransactionStatusEnum.Pending && transaction?.from_account?.type === AccountTypeEnum.InternalPayment" (click)="finalizeInternalPaymentTransaction()" [disabled]="showLoading">Finalize</button>
</h1>

<div *ngIf="showLoading">
    <div>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>        
</div>

<div>
    <pre>{{ transaction | json }}</pre>
</div>