import { ChangeDetectionStrategy, Component, signal, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ProfileRoleInterface, UserInterface, UserStatusEnum } from '@kiddy-cash/common';
import { SnackBarService } from 'src/app/services';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  standalone: false,
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,  
})
export class UserComponent {
  app_users: UserInterface[] = [];
  UserStatusEnumValues = Object.values(UserStatusEnum);
  staticBaseUrl: string =  environment.staticBaseUrl;
  showLoading: boolean = false;

  userQueryForm: FormGroup = this.formBuilder.group({
    phone: '',
    email: ['', Validators.email],
    status: '',
  })

  displayedColumns: string[] = [
    'photo', 
    'name', 
    'phone',
    'email', 
    'country', 
    'dob', 
    'genderidentity', 
    'roles', 
    'created', 
  ];

  dataSource = new MatTableDataSource<UserInterface>([]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  selectedIndex: number = 0;

  step = signal(0);

  setStep(index: number) {
    this.step.set(index);
  }

  nextStep() {
    this.step.update(i => i + 1);
  }

  prevStep() {
    this.step.update(i => i - 1);
  }

  constructor(
    private userService: UserService,
    private snackBarService: SnackBarService,
    private formBuilder: FormBuilder,
  ) {

   }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  submitQuery(formData: any){
    const data = structuredClone(formData)
    Object.keys(data).forEach((key) => (data[key] === null || data[key] === '') && delete data[key]);
    this.queryForUsers(data)
  }

  queryForUsers(data: any) {
    this.showLoading = true;
    this.app_users = []
    this.selectedIndex = 0;

    this.userService.findUsers(data).subscribe(res => {
      const app_users: UserInterface[] = res.data.app_users;
      this.app_users = app_users;
      this.dataSource.data = app_users;
      app_users.length ? this.setStep(1) : this.setStep(2);
      this.showLoading = false;
    },(err: any) => {
      this.showLoading = false;
      this.snackBarService.presentToast(err.error.message, 'danger');
    })
  }

  onSelectedIndex(index: number) {
    this.selectedIndex = index;
  }

  getProfileRoles(profile_roles: ProfileRoleInterface[]): string {
    return profile_roles.flatMap(profile_role => profile_role.role.title).toString();
  }

}
