<h1 class="page-title">Internal Payments
    <button mat-fab [routerLink]="['/internal-payments', 'create']">
        <mat-icon>person_add</mat-icon>
    </button>
</h1>

<div *ngIf="showLoading">
    <div>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>        
</div>

<mat-accordion class="expansion-headers-align">
    <mat-expansion-panel [expanded]="step() === 0" (opened)="setStep(0)" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title> Query Data </mat-panel-title>
        <mat-panel-description>
          Fill in the form
          <mat-icon>search</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
  
        <form [formGroup]="internalPaymentAccountQueryForm" (ngSubmit)="submitQuery(internalPaymentAccountQueryForm.value)">
            <mat-form-field>
                <mat-label>Country</mat-label>
                <mat-select formControlName="$country.id$" >
                    <mat-option *ngFor="let country of countries" [value]="country.id">{{ country.name }}</mat-option>
                </mat-select>
            </mat-form-field>

            <br>

            <button mat-raised-button color="accent" [disabled]="showLoading">Query</button>
        </form>
    </mat-expansion-panel>
  
    <mat-expansion-panel [expanded]="step() === 1" (opened)="setStep(1)" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title> Search Results </mat-panel-title>
            <mat-panel-description>
                A Tabular Representation
                <mat-icon>database_search</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
  
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="flag">
                <th mat-header-cell *matHeaderCellDef>  </th>
                <td mat-cell *matCellDef="let account">
                    <img height="40" width="40" class="country-flag" alt="flag" src="{{ staticBaseUrl + '/flags/' + account.country.flag }}"/>
                </td>
            </ng-container>

            <ng-container matColumnDef="country_name">
                <th mat-header-cell *matHeaderCellDef> Country </th>
                <td mat-cell *matCellDef="let account"> {{ account.country.name }} </td>
            </ng-container>

            <ng-container matColumnDef="account_name">
                <th mat-header-cell *matHeaderCellDef> Account </th>
                <td mat-cell *matCellDef="let account"> {{ account.name }} </td>
            </ng-container>

            <ng-container matColumnDef="account_number">
                <th mat-header-cell *matHeaderCellDef> Account </th>
                <td mat-cell *matCellDef="let account"> {{ account.number }} </td>
            </ng-container>

            <ng-container matColumnDef="integrations">
                <th mat-header-cell *matHeaderCellDef> Integrations </th>
                <td mat-cell *matCellDef="let account"> {{ displayAccountIntegrations(account.account_integrations) | json }} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let account; columns: displayedColumns;" [routerLink]="['/internal-payments', account.id, 'transact']"></tr>
        </table>
        
        <mat-paginator [pageSizeOptions]="[5, 10, 20]"
            showFirstLastButtons
            aria-label="Select page of periodic elements">
        </mat-paginator>

        <mat-action-row>
            <button mat-button (click)="prevStep()">Previous</button>
            <button mat-button (click)="nextStep()">Next</button>
        </mat-action-row>
    </mat-expansion-panel>
  
    <mat-expansion-panel [expanded]="step() === 2" (opened)="setStep(2)" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title> Advanced Search Results </mat-panel-title>
            <mat-panel-description>
                The Raw Data as JSON
                <mat-icon>search_insights</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <ng-container *ngIf="accounts.length == 0">
            <p>No Results Found</p>
            <button mat-stroked-button (click)="setStep(0)">Back to Search</button>
        </ng-container>

        <ng-container *ngIf="accounts.length > 0">
            <mat-nav-list>
                <mat-list-item [routerLink]="['/internal-payments', accounts[selectedIndex].id]">
                    <img matListItemAvatar alt="avatar" src="{{ staticBaseUrl + '/flags/' + accounts[selectedIndex].country.flag }}"/>
                    <span matListItemLine>Account ID : {{ accounts[selectedIndex].id }}</span> 
                    <span matListItemLine>Account Name : {{ accounts[selectedIndex].name }}</span> 
                    <span matListItemLine>Account Number : {{ accounts[selectedIndex].number }}</span>
                </mat-list-item>
            </mat-nav-list>
        </ng-container>
        
        <ng-container *ngIf="accounts.length">
            <data-viewer [data]="accounts" (selectedIndex)="onSelectedIndex($event)"></data-viewer>
        </ng-container>

        <mat-action-row>
            <button mat-button (click)="prevStep()">Previous</button>
            <button mat-button (click)="nextStep()">End</button>
        </mat-action-row>
    </mat-expansion-panel>
</mat-accordion>
