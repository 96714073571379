import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IntegrationService, SnackBarService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
  standalone: false,
  selector: 'kc-admin-integration-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class IntegrationViewComponent {
  public integration_id!: string;
  integration!: any;
  showLoading: boolean = true;
  staticBaseUrl: string =  environment.staticBaseUrl;

  constructor(
    private activatedRoute: ActivatedRoute,
    private integrationService: IntegrationService,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit() {
    this.integration_id = this.activatedRoute.snapshot.paramMap.get('integration_id') as string;

    this.integrationService.getIntegration(this.integration_id).subscribe(res => {
      const integration: any = res.data.integration;
      this.integration = integration;
      this.showLoading = false;
    },
    (err: any) => {
      this.snackBarService.presentToast(err.error.message, 'danger');
      this.showLoading = false;
    })
  }
}
