import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { NotificationInterface, RolesEnum } from '@kiddy-cash/common';
import { Firestore, getFirestore, onSnapshot, doc, collection, query, orderBy } from '@angular/fire/firestore';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {
  firestore: Firestore = getFirestore();
  userRoles: RolesEnum[] = [];

  public _$systemAdmin = new BehaviorSubject<NotificationInterface[]>([]);
  public _$admin = new BehaviorSubject<NotificationInterface[]>([]);
  public _$auditor = new BehaviorSubject<NotificationInterface[]>([]);
  public _$analyst = new BehaviorSubject<NotificationInterface[]>([]);
  public _$investor = new BehaviorSubject<NotificationInterface[]>([]);
  public _$superAdmin = new BehaviorSubject<NotificationInterface[]>([]);

  constructor(
    private authService: AuthService,
  ) { 
  }

  async setUp() {
    this.authService._$user_roles
      .pipe(distinctUntilChanged())
      .subscribe((userRoles: RolesEnum[]) => {
      this.userRoles = userRoles;
      if (userRoles.length) this.subscribeToDatabases()
    });
  }

  async subscribeToDatabases() {
      const db = this.firestore;
      const userRoles = this.userRoles;

      for(const role of userRoles) {
        const topic = role.replace(' ', '_').toLowerCase();

        onSnapshot(query(collection(db, `role/${topic}/notification`), orderBy("notification.time", 'desc')), (snapshot) => {
          const notifications: NotificationInterface[] = [];

          snapshot.docs.forEach(doc => {
            const notification = doc.data()['notification'];
            notification.time = notification.time.toDate();
            notifications.push(notification as NotificationInterface);
          });

          this.setRoleNotifications(role, notifications);
        });
      } 
  }

  setRoleNotifications(role: RolesEnum, notifications: NotificationInterface[]) {
    switch(role) {
      case RolesEnum.SystemAdmin:
        this._$systemAdmin.next(notifications);
        break;
      case RolesEnum.Admin:
        this._$admin.next(notifications);
        break;
      case RolesEnum.Auditor:
        this._$auditor.next(notifications);
        break;
      case RolesEnum.Analyst:
        this._$analyst.next(notifications);
        break;
      case RolesEnum.Investor:
        this._$investor.next(notifications);
        break;
      case RolesEnum.SuperAdmin:
        this._$superAdmin.next(notifications);
        break;
    }
  }

}
