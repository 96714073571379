import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { StockInterface } from '@kiddy-cash/common';
import { StockService, SnackBarService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
  standalone: false,
  selector: 'kc-admin-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.scss']
})
export class StockComponent {
  displayedColumns: string[] = [
    'logo', 
    'id', 
    'name', 
    'description', 
    'ticker', 
    'exchange', 
    'active',
  ];

  showLoading: boolean = true;
  staticBaseUrl: string =  environment.staticBaseUrl;
  dataSource = new MatTableDataSource<StockInterface>([]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private stockService: StockService,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit() {
    this.stockService.getStocks().subscribe(res => {
      const stocks: StockInterface[] = res.data.stocks;
      this.dataSource.data = stocks;
      this.showLoading = false;
    },
    (err: any) => {
      this.snackBarService.presentToast(err.error.message, 'danger');
      this.showLoading = false;
    })
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
}
