// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: "AIzaSyDXq6FCLEKkTuLmYjSm2VgkQpPnC6wnJLg",
    authDomain: "dev-kiddy-cash.firebaseapp.com",
    databaseURL: "https://dev-kiddy-cash-default-rtdb.firebaseio.com",
    projectId: "dev-kiddy-cash",
    storageBucket: "dev-kiddy-cash.appspot.com",
    messagingSenderId: "558421973028",
    appId: "1:558421973028:web:937acdd90660e4357e60b1",
    measurementId: "G-QYNZY1972F"
  },
  fcm: {
    vapidKey: "BFyJXkHyWEDjVNZ2NgtB6aXiWVxYHEpkIuAC4pkaTmpjEJSzS3_40qAgL275wezjmJOoKg6XQSG_dBjsIMPGrIc",
  },
  recaptchaSiteKey: '6LfJlqwqAAAAAMN6oiVMJCSAKgJBDCJ88g_7O4-F',
  baseUrl: 'https://dev-admin.kiddy.cash',
  staticBaseUrl: 'https://dev-static.kiddy.cash',
  apiBaseUrl: 'https://dev-api.kiddy.cash/admin',
  production: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
