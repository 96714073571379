import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { getAuth, onAuthStateChanged, User } from '@angular/fire/auth';
import { AuthService } from 'src/app/services';

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const auth = getAuth();
  const authService = inject(AuthService);

  return new Promise((resolve) => {

    onAuthStateChanged(auth, async (user: User | null) => {
      if(user) {
        await authService.processUser(user);
        // allow the route
        resolve(true);
      } else {
        // Do not allow the route
        resolve(false);
        router.navigateByUrl('/')
      }
    });
    
  });
};
  