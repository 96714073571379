import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SchoolService {

  constructor(private http: HttpClient) { }

  findSchools(data: any) {
    return this.http.get<any>(`${environment.apiBaseUrl}/school`, { params: data });
  }

  getSchool(school_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/school/${school_id}`);
  }

  updateSchool(school_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/school/${school_id}`, data);
  }

  deleteSchool(school_id: string) {
    return this.http.delete<any>(`${environment.apiBaseUrl}/school/${school_id}`);
  }
}
