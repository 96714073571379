<h1 class="page-title">Country</h1>

<div *ngIf="showLoading">
    <div>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>        
</div>

<div>
    <pre>{{ country | json }}</pre>
</div>