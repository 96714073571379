<h1 class="page-title">Create Internal Payments Account Transaction</h1>

<div *ngIf="showLoading">
    <div>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>        
</div>

<div>
    <mat-list>
        <mat-list-item>
            <img *ngIf="account" matListItemIcon alt="avatar" src="{{ staticBaseUrl + '/flags/' + account.country.flag }}"/>
            <span matListItemLine>Account Name : {{ account?.name }}</span> 
            <span matListItemLine>Account Number : {{ account?.number }}</span>
        </mat-list-item>
    </mat-list>

    <br>

    <form [formGroup]="internalPaymentAccountTransactionForm" (ngSubmit)="submitForm(internalPaymentAccountTransactionForm.value)">
        <mat-form-field>
            <mat-label>Integration</mat-label>
            <mat-select matInput formControlName="integration_id">
                <mat-option *ngFor="let account_integration of account?.account_integrations" [value]="account_integration.integration.id">{{ account_integration.integration.name }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>To Account Number</mat-label>
            <input 
                formControlName="to_account_number" 
                type="text" 
                matInput
                minlength="10"
                maxlength="10"
            >
        </mat-form-field>

        <mat-form-field>
            <mat-label>Amount</mat-label>
            <input formControlName="amount" type="number" matInput>
        </mat-form-field>

        <br>

        <button mat-raised-button color="accent" [disabled]="showLoading">Transact</button>
    </form>
</div>