import { Component } from '@angular/core';

@Component({
  standalone: false,
  selector: 'kc-admin-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})
export class TaskComponent {

}
