import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  constructor(private http: HttpClient) { }

  findIntegrations(data: any) {
    return this.http.get<any>(`${environment.apiBaseUrl}/integration`, { params: data });
  }

  getIntegration(integration_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/integration/${integration_id}`);
  }

  updateIntegration(integration_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/integration/${integration_id}`, data);
  }

  deleteIntegration(integration_id: string) {
    return this.http.delete<any>(`${environment.apiBaseUrl}/integration/${integration_id}`);
  }

}
