<h1 class="page-title">Know Your Business</h1>

<div *ngIf="showLoading">
    <div>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>        
</div>

<mat-accordion class="expansion-headers-align">
    <mat-expansion-panel [expanded]="step() === 0" (opened)="setStep(0)" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title> Query Data </mat-panel-title>
        <mat-panel-description>
          Fill in the form
          <mat-icon>search</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
  
        <form [formGroup]="kybQueryForm" (ngSubmit)="submitQuery(kybQueryForm.value)">
            <mat-form-field>
                <mat-label>KYB Passed</mat-label>
                <mat-select formControlName="passed">
                    <mat-option [value]="true">True</mat-option>
                    <mat-option [value]="false">False</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>KYB Phone</mat-label>
                <input formControlName="phone" matInput>
            </mat-form-field>
            <mat-form-field>
                <mat-label>KYB Email</mat-label>
                <input formControlName="email" matInput>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Business ID</mat-label>
                <input formControlName="business_id" matInput>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Business Type</mat-label>
                <mat-select formControlName="$business.type$">
                    <mat-option *ngFor="let type of BusinessTypeEnumalues" [value]="type">{{ type }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Business Manager Phone</mat-label>
                <input formControlName="$business.managers.profile.user.phone$" matInput>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Business Manager Email</mat-label>
                <input formControlName="$business.managers.profile.user.email$" matInput>
            </mat-form-field>

            <br>

            <button mat-raised-button color="accent" [disabled]="showLoading">Query</button>
        </form>
    </mat-expansion-panel>
  
    <mat-expansion-panel [expanded]="step() === 1" (opened)="setStep(1)" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title> Search Results </mat-panel-title>
            <mat-panel-description>
                A Tabular Representation
                <mat-icon>database_search</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
  
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="business_logo">
                <th mat-header-cell *matHeaderCellDef>  </th>
                <td mat-cell *matCellDef="let kyb">
                    <img height="40" width="40" class="logo-thumbnail" alt="logo" src="{{ kyb.business?.logoversion ? ( staticBaseUrl + '/businesss/' + kyb.business?.id + '/logos/' + kyb.business?.logoversion + '.jpeg' ) : ( staticBaseUrl + '/defaults/business.svg') }}"/>
                </td>
            </ng-container>

            <ng-container matColumnDef="passed">
                <th mat-header-cell *matHeaderCellDef> KYB Passed </th>
                <td mat-cell *matCellDef="let kyb"> {{ kyb.passed }} </td>
            </ng-container>

            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef> KYB Phone </th>
                <td mat-cell *matCellDef="let kyb"> {{ kyb.phone }} </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef> KYB Email </th>
                <td mat-cell *matCellDef="let kyb"> {{ kyb.email }} </td>
            </ng-container>

            <ng-container matColumnDef="business_name">
                <th mat-header-cell *matHeaderCellDef> Business Name </th>
                <td mat-cell *matCellDef="let kyb"> {{ kyb.business.name }} </td>
            </ng-container>

            <ng-container matColumnDef="business_country">
                <th mat-header-cell *matHeaderCellDef> Business Country </th>
                <td mat-cell *matCellDef="let kyb"> {{ kyb.business.country.name }} </td>
            </ng-container>

            <ng-container matColumnDef="business_company_type">
                <th mat-header-cell *matHeaderCellDef> Business Type </th>
                <td mat-cell *matCellDef="let kyb"> {{ kyb.business.company_type }} </td>
            </ng-container>

            <ng-container matColumnDef="business_total_followers">
                <th mat-header-cell *matHeaderCellDef> Followers </th>
                <td mat-cell *matCellDef="let kyb"> {{ kyb.business.total_followers }} </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let kyb">
                    <button mat-button color="accent" (click)="updateKyb(kyb.id, true)" [disabled]="showLoading" *ngIf="kyb.passed === false">Pass User</button>
                    <button mat-button color="primary" (click)="updateKyb(kyb.id, false)" [disabled]="showLoading" *ngIf="kyb.passed === true">Fail User</button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let kyb; columns: displayedColumns;"></tr>
        </table>
        
        <mat-paginator [pageSizeOptions]="[5, 10, 20]"
            showFirstLastButtons
            aria-label="Select page of periodic elements">
        </mat-paginator>

        <mat-action-row>
            <button mat-button (click)="prevStep()">Previous</button>
            <button mat-button (click)="nextStep()">Next</button>
        </mat-action-row>
    </mat-expansion-panel>
  
    <mat-expansion-panel [expanded]="step() === 2" (opened)="setStep(2)" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title> Advanced Search Results </mat-panel-title>
            <mat-panel-description>
                The Raw Data as JSON
                <mat-icon>search_insights</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <ng-container *ngIf="kybs.length == 0">
            <p>No Results Found</p>
            <button mat-stroked-button (click)="setStep(0)">Back to Search</button>
        </ng-container>

        <ng-container *ngIf="kybs.length > 0">
            <mat-nav-list>
                <mat-list-item>
                    <img matListItemAvatar alt="avatar" src="{{ kybs[selectedIndex].business.logoversion ? ( staticBaseUrl + '/businesss/' + kybs[selectedIndex].business.id + '/logos/' + kybs[selectedIndex].business.logoversion + '.jpeg' ) : ( staticBaseUrl + '/defaults/business.svg') }}"/>
                    <span matListItemLine>KYB ID : {{ kybs[selectedIndex].id }}</span> 
                    <span matListItemLine>KYB Phone : {{ kybs[selectedIndex].phone }}</span> 
                    <span matListItemLine>KYB Email : {{ kybs[selectedIndex].email }}</span>
                </mat-list-item>
            </mat-nav-list>
        </ng-container>
        
        <ng-container *ngIf="kybs.length">
            <data-viewer [data]="kybs" (selectedIndex)="onSelectedIndex($event)"></data-viewer>
        </ng-container>

        <mat-action-row>
            <button mat-button (click)="prevStep()">Previous</button>
            <button mat-button (click)="nextStep()">End</button>
        </mat-action-row>
    </mat-expansion-panel>
</mat-accordion>
