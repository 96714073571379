import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
// Angular Material
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
// firebase stuff
import { FirebaseApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAnalytics, getAnalytics, ScreenTrackingService, UserTrackingService, AnalyticsModule } from '@angular/fire/analytics';
/*
  For whatever reason I struggled to get AppCheck Working so disabled

  import { initializeAppCheck, ReCaptchaEnterpriseProvider, provideAppCheck } from '@angular/fire/app-check';
*/
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideDatabase, getDatabase } from '@angular/fire/database';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { providePerformance, getPerformance } from '@angular/fire/performance';
import { provideRemoteConfig, getRemoteConfig } from '@angular/fire/remote-config';
import { provideStorage, getStorage } from '@angular/fire/storage';
// Pages
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AdminComponent } from './pages/admin/admin.component';
import { AdminViewComponent } from './pages/admin/view/view.component';
import { AccountComponent } from './pages/account/account.component';
import { AccountViewComponent } from './pages/account/view/view.component';
import { InternalPaymentsComponent } from './pages/internal-payments/internal-payments.component';
import { InternalPaymentsCreateComponent } from './pages/internal-payments/create/create.component';
import { InternalPaymentsTransactComponent } from './pages/internal-payments/transact/transact.component';
import { AllowanceComponent } from './pages/allowance/allowance.component';
import { AllowanceViewComponent } from './pages/allowance/view/view.component';
import { BusinessComponent } from './pages/business/business.component';
import { BusinessViewComponent } from './pages/business/view/view.component';
import { CountryComponent } from './pages/country/country.component';
import { CountryViewComponent } from './pages/country/view/view.component';
import { FamilyComponent } from './pages/family/family.component';
import { FamilyViewComponent } from './pages/family/view/view.component';
import { GoalComponent } from './pages/goal/goal.component';
import { GoalViewComponent } from './pages/goal/view/view.component';
import { IntegrationComponent } from './pages/integration/integration.component';
import { IntegrationViewComponent } from './pages/integration/view/view.component';
import { InvestmentComponent } from './pages/investment/investment.component';
import { InvestmentViewComponent } from './pages/investment/view/view.component';
import { KiddyComponent } from './pages/kiddy/kiddy.component';
import { KiddyViewComponent } from './pages/kiddy/view/view.component';
import { KnowYourBusinessComponent } from './pages/know-your-business/know-your-business.component';
import { KnowYourBusinessViewComponent } from './pages/know-your-business/view/view.component';
import { KnowYourCustomerComponent } from './pages/know-your-customer/know-your-customer.component';
import { KnowYourCustomerViewComponent } from './pages/know-your-customer/view/view.component';
import { KnowYourSchoolComponent } from './pages/know-your-school/know-your-school.component';
import { KnowYourSchoolViewComponent } from './pages/know-your-school/view/view.component';
import { LoanComponent } from './pages/loan/loan.component';
import { LoanViewComponent } from './pages/loan/view/view.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { PaymentCardComponent } from './pages/payment-card/payment-card.component';
import { PaymentCardViewComponent } from './pages/payment-card/view/view.component';
import { SchoolComponent } from './pages/school/school.component';
import { SchoolViewComponent } from './pages/school/view/view.component';
import { TaskComponent } from './pages/task/task.component';
import { TaskViewComponent } from './pages/task/view/view.component';
import { TransactionComponent } from './pages/transaction/transaction.component';
import { TransactionViewComponent } from './pages/transaction/view/view.component';
import { StockComponent } from './pages/stock/stock.component';
import { StockViewComponent } from './pages/stock/view/view.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ProfileViewComponent } from './pages/profile/view/view.component';
import { UserComponent } from './pages/user/user.component';
import { UserViewComponent } from './pages/user/view/view.component';
// Components
import { DataViewerComponent } from './components/data-viewer/data-viewer.component';
// services
import { AuthService } from './services';

const firebaseApp: FirebaseApp = initializeApp(environment.firebase)

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotFoundComponent,
    AccountComponent,
    AccountViewComponent,
    InternalPaymentsComponent,
    InternalPaymentsCreateComponent,
    InternalPaymentsTransactComponent,
    AdminComponent,
    AdminViewComponent,
    AllowanceComponent,
    AllowanceViewComponent,
    BusinessComponent,
    BusinessViewComponent,
    CountryComponent,
    CountryViewComponent,
    FamilyComponent,
    FamilyViewComponent,
    GoalComponent,
    GoalViewComponent,
    IntegrationComponent,
    IntegrationViewComponent,
    InvestmentComponent,
    InvestmentViewComponent,
    KiddyComponent,
    KiddyViewComponent,
    KnowYourBusinessComponent,
    KnowYourBusinessViewComponent,
    KnowYourCustomerComponent,
    KnowYourCustomerViewComponent,
    KnowYourSchoolComponent,
    KnowYourSchoolViewComponent,
    LoanComponent,
    LoanViewComponent,
    NotificationsComponent,
    PaymentCardComponent,
    PaymentCardViewComponent,
    SchoolComponent,
    SchoolViewComponent,
    TaskComponent,
    TaskViewComponent,
    TransactionComponent,
    TransactionViewComponent,
    StockComponent,
    StockViewComponent,
    ProfileComponent,
    ProfileViewComponent,
    UserComponent,
    UserViewComponent,
    DataViewerComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatSidenavModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatGridListModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTableModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    AnalyticsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    provideFirebaseApp(() => {
      return firebaseApp
    }),
    provideAuth(() => getAuth(firebaseApp)),
    provideAnalytics(() => getAnalytics(firebaseApp)),
    /*
      For whatever reason I struggled to get AppCheck Working so disabled

      provideAppCheck(() => initializeAppCheck(firebaseApp, {
        provider: new ReCaptchaEnterpriseProvider(environment.recaptchaSiteKey),
        isTokenAutoRefreshEnabled: true,
      })),
    */
    provideDatabase(() => getDatabase(firebaseApp)),
    provideFirestore(() => getFirestore(firebaseApp)),
    provideFunctions(() => getFunctions(firebaseApp)),
    provideMessaging(() => getMessaging(firebaseApp)),
    providePerformance(() => getPerformance(firebaseApp)),
    provideRemoteConfig(() => getRemoteConfig(firebaseApp)),
    provideStorage(() => getStorage(firebaseApp)),
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [
        AuthService, 
      ],
      useFactory: (
        authService: AuthService,
      ) => async () => {
        await authService.setUp()
      },
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
