<h1 class="page-title">Create Internal Payments Account Integration</h1>

<div *ngIf="showLoading">
    <div>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>        
</div>

<div>
    <form [formGroup]="internalPaymentAccountIntegrationForm" (ngSubmit)="submitForm(internalPaymentAccountIntegrationForm.value)">
        <mat-form-field>
            <mat-label>Account</mat-label>
            <mat-select formControlName="internal_account_id" >
                <mat-option *ngFor="let account of accounts" [value]="account.id">{{ account.country.name }} - {{ account.number }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Integration</mat-label>
            <mat-select formControlName="integration_id" >
                <mat-option *ngFor="let integration of integrations" [value]="integration.id">{{ integration.id }} - {{ integration.name }} - {{ integration.service }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>External ID</mat-label>
            <input formControlName="external_id" matInput>
        </mat-form-field>

        <br>

        <button mat-raised-button color="accent" [disabled]="showLoading">Integrate</button>
    </form>
</div>