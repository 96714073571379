import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  constructor(private http: HttpClient) { }

  getStocks() {
    return this.http.get<any>(`${environment.apiBaseUrl}/stock`);
  }

  getStock(stock_id: number) {
    return this.http.get<any>(`${environment.apiBaseUrl}/stock/${stock_id}`);
  }

  updateStock(stock_id: number, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/stock/${stock_id}`, data);
  }

}
