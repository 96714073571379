import { ChangeDetectionStrategy, Component, signal, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AccountIntegrationInterface, AccountInterface, CountryInterface } from '@kiddy-cash/common';
import { CountryService, InternalPaymentsService, SnackBarService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
  standalone: false,
  selector: 'app-internal-payments',
  templateUrl: './internal-payments.component.html',
  styleUrls: ['./internal-payments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,  
})
export class InternalPaymentsComponent {
  countries: CountryInterface[] = [];
  accounts: AccountInterface[] = [];
  staticBaseUrl: string =  environment.staticBaseUrl;
  showLoading: boolean = false;

  internalPaymentAccountQueryForm: FormGroup = this.formBuilder.group({
    '$country.id$': '',
  })

  displayedColumns: string[] = [
    'flag', 
    'country_name', 
    'account_name', 
    'account_number',
    'integrations', 
  ];


  dataSource = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  selectedIndex: number = 0;

  step = signal(0);

  setStep(index: number) {
    this.step.set(index);
  }

  nextStep() {
    this.step.update(i => i + 1);
  }

  prevStep() {
    this.step.update(i => i - 1);
  }

  constructor(
    private countryService: CountryService,
    private internalPaymentsService: InternalPaymentsService,
    private snackBarService: SnackBarService,
    private formBuilder: FormBuilder,
  ) {

   }

  ngOnInit() {
    this.countryService.getCountries().subscribe(res => {
      const countries: CountryInterface[] = res.data.countries;
      this.countries = countries;
      this.showLoading = false;
    },
    (err: any) => {
      this.snackBarService.presentToast(err.error.message, 'danger');
      this.showLoading = false;
    })
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  submitQuery(formData: any){
    const data = structuredClone(formData)
    Object.keys(data).forEach((key) => (data[key] === null || data[key] === '') && delete data[key]);
    this.queryForInternalPayments(data)
  }

  queryForInternalPayments(data: any) {
    this.showLoading = true;
    this.accounts = []
    this.selectedIndex = 0;

    this.internalPaymentsService.findInternalPayments(data).subscribe(res => {
      const accounts: any[] = res.data.accounts;
      this.accounts = accounts;
      this.dataSource.data = accounts;
      accounts.length ? this.setStep(1) : this.setStep(2);
      this.showLoading = false;
    },(err: any) => {
      this.showLoading = false;
      this.snackBarService.presentToast(err.error.message, 'danger');
    })
  }

  onSelectedIndex(index: number) {
    this.selectedIndex = index;
  }

  displayAccountIntegrations(account_integrations: AccountIntegrationInterface[]): object[] {
    const integrations = account_integrations.map((account_integration) => {
      return {
        id: account_integration.integration.id,
        name: account_integration.integration.name,
        service: account_integration.integration.service,
      }
    })

    return integrations;
  }

}
