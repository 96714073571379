import { Component } from '@angular/core';

@Component({
  standalone: false,
  selector: 'kc-admin-allowance',
  templateUrl: './allowance.component.html',
  styleUrls: ['./allowance.component.scss']
})
export class AllowanceComponent {

}
