import { Component } from '@angular/core';

@Component({
  standalone: false,
  selector: 'kc-admin-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss']
})
export class BusinessComponent {

}
