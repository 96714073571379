<h1 class="page-title">Stocks</h1>

<div *ngIf="showLoading">
    <div>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>        
</div>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="logo">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let stock">
            <img height="40" width="40" class="stock-logo" src="{{ staticBaseUrl + '/stocks/' + stock.ticker + '.png'}}" />
        </td>
    </ng-container>

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let stock"> {{ stock.id }} </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let stock"> {{ stock.name }} </td>
    </ng-container>

    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> Description </th>
        <td mat-cell *matCellDef="let stock"> {{ stock.description }} </td>
    </ng-container>

    <ng-container matColumnDef="ticker">
        <th mat-header-cell *matHeaderCellDef> Ticker </th>
        <td mat-cell *matCellDef="let stock"> {{ stock.ticker }} </td>
    </ng-container>

    <ng-container matColumnDef="exchange">
        <th mat-header-cell *matHeaderCellDef> Exchange </th>
        <td mat-cell *matCellDef="let stock"> {{ stock.exchange }} </td>
    </ng-container>

    <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef> Active </th>
        <td mat-cell *matCellDef="let stock"> {{ stock.active }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let stock; columns: displayedColumns;" [routerLink]="['/stock', stock.id]"></tr>
</table>

<mat-paginator [pageSizeOptions]="[20, 50, 100, 250]"
    showFirstLastButtons
    aria-label="Select page of periodic elements">
</mat-paginator>