import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

@Component({
  standalone: false,
  selector: 'data-viewer',
  templateUrl: './data-viewer.component.html',
  styleUrl: './data-viewer.component.scss'
})
export class DataViewerComponent {
  @Input() data: any;

  @Output() selectedIndex = new EventEmitter<number>();

  ngOnInit() {
    this.renderData()
    this.selectedIndex.emit(0);
  }

  ngOnChanges(changes: SimpleChanges) {
    const $jsontree: any = (window as any).$jsontree;
    const data: any = changes['data'].currentValue;
    $jsontree.setJson('data-viewer', data);
  }

  renderData() {
    const component = this;
    const $jsontree: any = (window as any).$jsontree;
  
    $jsontree.render(document.getElementById('data-viewer'), {
      data: this.data,
      showObjectSizes: false,
      fileDroppingEnabled: false,
      showArrayIndexBrackets: false,
      showDataTypes: false,
      showPropertyNameQuotes: false,
      showEmailOpenButtons: false,
      showChildIndexes: false,
      rootName: 'data',
      title: {
        text: '',
        enableFullScreenToggling: false,
        showFullScreenButton: false,
      },
      controlPanel: {
        enabled: false,
      },
      sideMenu: {
        enabled: false,
      },
      parse: {
        stringsToDates: true,
      },
      allowEditing: {
        enabled: false,
        booleanValues: false,
        decimalValues: false,
        numberValues: false,
        stringValues: false,
        dateValues: false,
        bigIntValues: false,
        guidValues: false,
        colorValues: false,
        propertyNames: false,
        urlValues: false,
        emailValues: false,
        regExpValues: false,
        symbolValues: false,
        imageValues: false,
        bulk: false
      },
      lineNumbers: {
        enabled: false,
      },
      events: {
        onBackPage: function (jsonTreeElement: HTMLElement) {
          const element = jsonTreeElement.querySelector(".data-array-index");
          const index = Number(element?.innerHTML);
          component.selectedIndex.emit(index);
        },
        onNextPage: function (jsonTreeElement: HTMLElement) {
          const element = jsonTreeElement.querySelector(".data-array-index");
          const index = Number(element?.innerHTML);
          component.selectedIndex.emit(index);
        },
      }
    });
  }}
