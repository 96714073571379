import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  findUsers(data: any) {
    return this.http.get<any>(`${environment.apiBaseUrl}/user`, { params: data });
  }

  getUser(user_id: string) {
    return this.http.get<any>(`${environment.apiBaseUrl}/user/${user_id}`);
  }

  updateUser(user_id: string, data: any) {
    return this.http.post<any>(`${environment.apiBaseUrl}/user/${user_id}`, data);
  }

  deleteUser(user_id: string) {
    return this.http.delete<any>(`${environment.apiBaseUrl}/user/${user_id}`);
  }

}
