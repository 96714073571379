<h1 class="page-title">Countries</h1>

<div *ngIf="showLoading">
    <div>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>        
</div>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let country"> {{ country.id }} </td>
    </ng-container>

    <ng-container matColumnDef="flag">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let country">
            <img height="40" width="40" class="country-flag" src="{{ staticBaseUrl + '/flags/' + country.flag }}" />
        </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let country"> {{ country.name }} </td>
    </ng-container>

    <ng-container matColumnDef="iso2">
        <th mat-header-cell *matHeaderCellDef> ISO2 </th>
        <td mat-cell *matCellDef="let country"> {{ country.iso2 }} </td>
    </ng-container>

    <ng-container matColumnDef="iso3">
        <th mat-header-cell *matHeaderCellDef> ISO3 </th>
        <td mat-cell *matCellDef="let country"> {{ country.iso3 }} </td>
    </ng-container>

    <ng-container matColumnDef="phone_code">
        <th mat-header-cell *matHeaderCellDef> Phone Code </th>
        <td mat-cell *matCellDef="let country"> {{ country.phone_code }} </td>
    </ng-container>

    <ng-container matColumnDef="dollar_rate">
        <th mat-header-cell *matHeaderCellDef> Dollar Rate </th>
        <td mat-cell *matCellDef="let country"> {{ country.dollar_rate }} </td>
    </ng-container>

    <ng-container matColumnDef="currency_code">
        <th mat-header-cell *matHeaderCellDef> Currency Code </th>
        <td mat-cell *matCellDef="let country"> {{ country.currency.code }} </td>
    </ng-container>

    <ng-container matColumnDef="currency_name">
        <th mat-header-cell *matHeaderCellDef> Currency Name </th>
        <td mat-cell *matCellDef="let country"> {{ country.currency.name }} </td>
    </ng-container>

    <ng-container matColumnDef="currency_symbol">
        <th mat-header-cell *matHeaderCellDef> Currency Symbol </th>
        <td mat-cell *matCellDef="let country"> {{ country.currency.symbol }} </td>
    </ng-container>

    <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef> Active </th>
        <td mat-cell *matCellDef="let country"> {{ country.active }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let country; columns: displayedColumns;" [routerLink]="['/country', country.id]"></tr>
</table>

<mat-paginator [pageSizeOptions]="[250, 100, 50, 20]"
    showFirstLastButtons
    aria-label="Select page of periodic elements">
</mat-paginator>