import { Component, signal, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { KnowYourSchoolInterface, ProfileRoleInterface } from '@kiddy-cash/common';
import { KnowYourSchoolService, SnackBarService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
  standalone: false,
  selector: 'app-know-your-school',
  templateUrl: './know-your-school.component.html',
  styleUrl: './know-your-school.component.scss'
})
export class KnowYourSchoolComponent {
  kyss: KnowYourSchoolInterface[] = [];
  staticBaseUrl: string =  environment.staticBaseUrl;
  showLoading: boolean = false;

  kysQueryForm: FormGroup = this.formBuilder.group({
    passed: '',
    phone: '',
    email: ['', Validators.email],
    school_id: '',
    '$school.type$': '',
    '$school.managers.profile.user.phone$': '',
    '$school.managers.profile.user.email$': ['', Validators.email],
  })

  displayedColumns: string[] = [
    'school_logo', 
    'passed',
    'phone',
    'email', 
    'school_name', 
    'school_country',
    'action', 
  ];

  dataSource = new MatTableDataSource<KnowYourSchoolInterface>([]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  selectedIndex: number = 0;

  step = signal(0);

  setStep(index: number) {
    this.step.set(index);
  }

  nextStep() {
    this.step.update(i => i + 1);
  }

  prevStep() {
    this.step.update(i => i - 1);
  }

  queryData: object = {}
 
  constructor(
    private kysService: KnowYourSchoolService,
    private snackBarService: SnackBarService,
    private formBuilder: FormBuilder,
  ) {

   }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  submitQuery(formData: any){
    const data = structuredClone(formData)
    Object.keys(data).forEach((key) => (data[key] === null || data[key] === '') && delete data[key]);
    this.queryForKyss(data)
  }

  queryForKyss(data: any) {
    this.showLoading = true;
    this.kyss = []
    this.selectedIndex = 0;

    this.queryData = data;

    this.kysService.queryKYSs(data).subscribe(res => {
      const kyss: KnowYourSchoolInterface[] = res.data.kyss;
      this.kyss = kyss;
      this.dataSource.data = kyss;
      kyss.length ? this.setStep(1) : this.setStep(2);
      this.showLoading = false;
    },(err: any) => {
      this.showLoading = false;
      this.snackBarService.presentToast(err.error.message, 'danger');
    })
  }

  onSelectedIndex(index: number) {
    this.selectedIndex = index;
  }

  getProfileRoles(school_roles: ProfileRoleInterface[]): string {
    return school_roles.flatMap(school_role => school_role.role.title).toString();
  }

  updateKys(kys_id: number, passed: boolean) {
    this.showLoading = true;
    this.kysService.updateKYS(kys_id, { passed: passed }).subscribe(res => {
      this.snackBarService.presentToast(res.message, 'success');
      this.queryForKyss(this.queryData);
    },(err: any) => {
      this.showLoading = false;
      this.snackBarService.presentToast(err.error.message, 'danger');
    })
  }

}
